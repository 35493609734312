const pointerBase = {
    data: function() {
        return {
            add: true,
            pointers: [],

            pointer: {
                pointerID: 0,
                items: [],
                results: [],
                searched: false
            },
        }
    },
    mounted: function () {
        // gelijk de pointers ophalen voor de huidige weergave
        this.getResults();
    },
    methods: {
        /**
         * Bepaal de cursor positie
         *
         * @param e
         * @returns {{top: number, left: number}}
         */
        getCursorPosition: function(e) {
            let that = this;
            let container = that.$el;
            let offset = that.$refs.list.getBoundingClientRect();

            // pixel offset bepalen
            let top = e.pageY - offset.y - window.scrollY;
            let left = e.pageX - offset.x - window.scrollX;

            let height = container.clientHeight;
            let width = container.clientWidth;

            // percentage offset bepalen
            top = (top / height) * 100;
            left = (left / width) * 100;

            return {
                top: top,
                left: left
            };
        },
        resetData: function() {
            let that = this;

            that.add = true;
            that.pointers = [];

            that.resetPointer();
        },
        resetPointer: function() {
            let that = this;

            that.pointer = {
                pointerID: 0,
                items: [],
                results: [],
                searched: false
            };
        },
        openPointer: function() {
            console.error('openPointer not implemented');
        },
        closePointer: function() {
            console.error('closePointer not implemented');
        },
        /**
         * Actie uitvoeren op de geselecteerde pointer
         *
         * @param _e
         * @param callback
         */
        select: function(_e) {

            let that = this;
            that.add = false;

            let dragging = false;
            let startX = _e.pageX;
            let startY = _e.pageY;
            let pointer = _e.target;

            let mousemove = function (e) {
                if (
                    (startX - e.pageX <= -10 || startX - e.pageX >= 10) ||
                    (startY - e.pageY <= -10 || startY - e.pageY >= 10)) {
                    dragging = true;

                    let position = that.getCursorPosition(e);

                    pointer.style.top = position.top + '%';
                    pointer.style.left = position.left + '%';

                } else {
                    dragging = false;
                }
            };

            let mouseup = function (e) {
                pointer.removeEventListener('mousemove', mousemove);
                pointer.removeEventListener('mouseup', mouseup);

                if (! dragging) {
                    that.open(pointer);
                } else {
                    that.update(e, pointer);
                }

                setTimeout(function () {
                    that.add = true;
                }, 250);
            };

            pointer.addEventListener('mousemove', mousemove);
            pointer.addEventListener('mouseup', mouseup);
        },

        /**
         * Maak een pointer aan doormiddel van een klik op de parent
         * @param e
         */

        create: function (e) {
            if (! this.add) {
                return false;
            }

            let that = this;
            let position = this.getCursorPosition(e);

            let form = document.getElementById('pointer-create');
            let data = new FormData(form);

            for (let key in position) {
                data.append(key, position[key]);
            }

            axios({
                method: form.method,
                url: form.action,
                data: data,
            }).then(function (response) {
                that.handleResponse(response);

                if (response.data.hasOwnProperty('data')) {
                    let pointer = response.data.data;

                    that.pointers.push(pointer);
                    that.pointer.pointerID = pointer.pointerID;
                    that.pointer.items = [];

                    that.openPointer();
                }
            }).catch(function (response) {
                that.handleResponse(response);
            });
        },

        /**
         * Pointer item aanmaken
         * @param e
         * @returns {boolean}
         */
        createItem: function(e) {
            e.preventDefault();

            let that = this;
            let form = e.target;
            let data = new FormData(form);

            axios({
                method: form.method,
                url: form.action,
                data: data,
            }).then(function (response) {
                that.handleResponse(response);
                if (response.data.hasOwnProperty('data')) {
                    that.pointer.items = response.data.data;
                }
            }).catch(function (response) {
                that.handleResponse(response);
            });

            return false;
        },

        /**
         * Update pointer
         *
         * @param e
         * @param pointer
         * @returns {boolean}
         */
        update: function (e, pointer) {
            let that = this;
            let position = this.getCursorPosition(e);

            let form = document.getElementById('pointer-update');
            let data = new FormData(form);

            for (let key in position) {
                data.append(key, position[key]);
            }

            axios({
                method: form.method,
                url: form.action.replace(':id', pointer.dataset.id),
                data: data,
            }).then(function (response) {
                that.handleResponse(response);
                if (response.data.hasOwnProperty('data')) {
                    that.pointers = response.data.data;
                }
            }).catch(function (response) {
                that.handleResponse(response);
            });
        },

        /**
         * Pointer item bijwerken
         *
         * @param e
         */
        updateItem: function(e) {
            let that = this;

            let form = e.target.form;
            let data = new FormData(form);

            axios({
                method: form.method,
                url: form.action,
                data: data,
            }).then(function (response) {
                that.handleResponse(response);
                if (response.data.hasOwnProperty('data')) {
                    that.pointer.items = response.data.data;
                }
            }).catch(function (response) {
                that.handleResponse(response);
            });
        },

        /**
         * Pointer verwijderen
         *
         * @param e
         * @returns {boolean}
         */
        destroy: function(e) {
            e.preventDefault();

            if (confirm('Weet je het zeker dat je de pointer met bijbehorende items wilt verwijderen?') !== true) {
                return false;
            }

            let that = this;
            let form = e.target;
            let data = new FormData(form);

            axios({
                method: form.method,
                url: form.action,
                data: data,
            }).then(function (response) {
                that.handleResponse(response);
                if (response.data.hasOwnProperty('data')) {
                    that.pointers = response.data.data;
                    that.close();
                }
            }).catch(function (response) {
                that.handleResponse(response);
            });

            return false;
        },

        /**
         * Pointer item verwijderen
         *
         * @param e
         * @returns {boolean}
         */
        destroyItem: function(e) {
            e.preventDefault();

            if (confirm('Weet je het zeker dat je dit item wilt verwijderen?') !== true) {
                return false;
            }

            let that = this;
            let form = e.target;
            let data = new FormData(form);

            axios({
                method: form.method,
                url: form.action,
                data: data,
            }).then(function (response) {
                that.handleResponse(response);

                if (response.data.hasOwnProperty('data')) {
                    that.pointer.items = response.data.data;
                }
            }).catch(function (response) {
                that.handleResponse(response);
            });

            return false;
        },

        /**
         * Pointer openen
         *
         * @param pointer
         */
        open: function (pointer) {
            let that = this;

            axios.get(pointer.dataset.url.replace(':id', pointer.dataset.id)).then(function (response) {
                that.openPointer();
                that.pointer.pointerID = pointer.dataset.id;
                that.pointer.items = response.data;
            });
        },

        close: function() {
            let that = this;

            that.resetPointer();
            that.closePointer();
        },

        /**
         * Response handler (placeholder)
         * @param response
         */
        handleResponse: function(response) {
            console.log(response, typeof response);
        }
    }
};