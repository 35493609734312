Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("save-popup")) {
        Global.components.push(
            new Vue({
                el: '#save-popup',
                store,
                data: function () {
                    return {
                        newBoardExpanded: false,
                        popupData: null,
                        searchQuery: '',
                        boardTypeUrl: null,
                        boardTitle: null,
                        createBoardSuccessfull: false,
                        newStoredID: null,
                        newBoardMessage: '',
                        confirmWindow: null,
                        dropdownVisible: null,
                        variantSelected: null,
                        pointerSaveClicked: null,
                        pointerBottomBoundary: false,
                        pointerRightBoundary: false,
                        pointerTopBoundary: false,
                        pointerLeftBoundary: false
                    }
                },
                computed: {
                    showSavePopup: {
                        get: function () {
                            return this.$store.getters.showSavePopup
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setShowSavePopup', newValue)
                        }
                    },
                    pointerSelected: {
                        get: function () {
                            return this.$store.getters.pointerSelected
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setPointerSelected', newValue)
                        }
                    },
                    saveDataUrl: {
                        get: function () {
                            return this.$store.getters.saveDataUrl
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setSaveDataUrl', newValue)
                        }
                    },
                    saveDataType: {
                        get: function () {
                            return this.$store.getters.saveDataType
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setSaveDataType', newValue)
                        }
                    },
                    moodboardItems: {
                        get: function () {
                            return this.$store.getters.moodboardItems;
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setMoodboardItems', newValue)
                        }
                    },
                    projectItems: {
                        get: function () {
                            return this.$store.getters.projectItems;
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setProjectItems', newValue)
                        }
                    },
                    messageText: {
                        get: function () {
                            return this.$store.getters.messageText
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setMessageText', newValue)
                        }
                    },
                    showMessage: {
                        get: function () {
                            return this.$store.getters.showMessage
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setShowMessage', newValue)
                        }
                    },
                    lowerCaseSearchQuery: function () {
                        return this.searchQuery.toLowerCase()
                    }
                },
                watch: {
                    saveDataUrl: function () {
                        this.getPopupData();
                    },

                    showSavePopup: function (newValue) {
                        this.variantSelected = null;

                        if(newValue) {
                            this.checkPointerContentBoundaries();
                        }
                    },
                    popupData: function (newValue) {
                        if(newValue !== null) {
                            this.checkPointerContentBoundaries();
                        }
                    },
                    pointerSaveClicked: function (newValue) {
                        if(newValue) {
                            this.checkPointerContentBoundaries();
                        }
                    }
                },
                mounted: function () {
                    this.getBoardsLists();
                },
                methods: {
                    togglePointerWindow: function (e, pointer) {
                        const that = this;

                        if (this.pointerSelected !== pointer.id) {
                            this.pointerSelected = pointer.id;

                            this.checkPointerContentBoundaries();
                        } else {
                            this.pointerSelected = null;

                            window.setTimeout(function () {
                                that.resetPointerContentBoundaries();
                            }, 400);
                        }

                        this.variantSelected = null;
                        this.pointerSaveClicked = null;
                    },
                    getBoardsLists: function () {
                        const that = this;

                        let lists = document.querySelectorAll('.save-popup__items');

                        if (!lists.length) {
                            return false;
                        }

                        // loop over de lijsten heen
                        lists.forEach(list => {
                            let data = list.dataset;

                            if (!data.hasOwnProperty('url')) {
                                return true;
                            }

                            API.GET(data.url).then(response => {
                                if (typeof(response) == 'undefined' || !response.hasOwnProperty('data')) {
                                    return false;
                                }

                                // stop de items in de goede var
                                if (response.source === 'project') {
                                    that.projectItems = response.data;
                                } else if (response.source === 'moodboard') {
                                    that.moodboardItems = response.data;
                                }
                            });
                        });
                    },
                    getPopupData: function () {
                        const that = this;

                        API.GET(that.saveDataUrl).then(response => {
                            that.popupData = response.data;

                            if (! this.pointerSelected) {
                                that.variantSelected = response.data;
                            }
                        });
                    },
                    resetPointerContentBoundaries: function () {
                        // this.$nextTick(() => {
                        //     this.pointerBottomBoundary = false;
                        //     this.pointerLeftBoundary = false;
                        //     this.pointerRightBoundary = false;
                        //     this.pointerTopBoundary = false;
                        // });
                    },
                    checkPointerContentBoundaries: function () {

                        this.$nextTick(() => {
                            let pointerContent = this.$el.querySelector('.save-popup__pointer--active');

                            this.pointerBottomBoundary = true;

                            if (pointerContent !== null) {

                                let imageAreaBoundary = this.$refs.imageArea.getBoundingClientRect();
                                let pointerContentBoundary = pointerContent.getBoundingClientRect();

                                let imageCenterPosition = imageAreaBoundary.left + (imageAreaBoundary.width / 2);
                                let pointerContentPosition = (pointerContentBoundary.left < imageCenterPosition ? 'left' : 'right');

                                if (pointerContentBoundary.left > imageCenterPosition) {
                                    this.pointerRightBoundary = true;
                                    this.pointerLeftBoundary = false;
                                } else {
                                    this.pointerRightBoundary = false;
                                    this.pointerLeftBoundary = true;
                                }
                            }
                        });
                    },
                    createBoard: function (e) {
                        const that = this;

                        this.$validator.validateAll().then(result => {
                            if (result) {
                                API.POST(that.boardTypeUrl, new FormData(e.target)).then(response => {
                                    if (response.source === 'project') {
                                        that.projectItems = response.data;
                                    } else if (response.source === 'moodboard') {
                                        that.moodboardItems = response.data;
                                    }

                                    that.newStoredID = response.stored;
                                    that.newBoardMessage = response.message;
                                    that.createBoardSuccessfull = true;

                                    that.boardTypeUrl = null;
                                    that.boardTitle = null;

                                    window.setTimeout(function () {
                                        that.newBoardExpanded = false;
                                    }, 2000);

                                    window.setTimeout(function () {
                                        that.createBoardSuccessfull = false;
                                    }, 2600);

                                });
                            }
                        });
                    },
                    saveItem: function (url) {
                        const that = this;
                        let data = new FormData();

                        data.append('link', this.variantSelected.link);
                        data.append('source', this.variantSelected.source);
                        data.append('sourceID', this.variantSelected.sourceID);

                        API.POST(url, data).then(response => {
                            that.messageText = response.message;
                            that.showMessage = true;
                            that.createBoardSuccessfull = false;
                            that.confirmWindow = null;
                        });
                    },
                    cancelSaveAction: function () {
                        this.confirmWindow = null;
                    },
                    hideResults: function () {
                        this.pointerSelected = null;
                        this.variantSelected = null;
                        this.dropdownVisible = null;
                        this.pointerSaveClicked = null;
                    },
                    onSaveImpressionClick: function() {
                        this.pointerSelected = null;
                        this.dropdownVisible = null;
                        this.variantSelected = this.popupData;
                        this.pointerSaveClicked = true;
                    },
                    selectVariant(variant) {
                        if (variant.in_stock) {
                            this.variantSelected = variant;
                            this.dropdownVisible = null;
                        }
                    }
                },
                created: function () {
                },
                destroyed: function () {
                }
            })
        )
    }
});
