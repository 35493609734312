Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("shoppingcart-confirm")) {
        Global.components.push(
            new Vue({
                el: '#shoppingcart-confirm',
                store,
                data: function () {
                    return {
                        otherDeliveryEnabled: 0,
                        orderInProgress: false,
                        //orderFile: null
                    }
                },
                computed: {
                    deliveryRequirements() {
                        return this.otherDeliveryEnabled ? 'required' : ''
                    }
                },
                watch: {},
                mounted: function () {
                },
                methods: {
                    placeOrder: function () {
                        const that = this;
                        that.orderInProgress = true;
                        if(document.getElementById("deliveryOther") != null) {
                            document.getElementById("deliveryOther").value = that.otherDeliveryEnabled ? 1 : 0;
                        }

                        let formData = new FormData(this.$refs.detailsForm);
                        formData.append('_method', 'put');

                        Global.SubmitForm(this.$refs.detailsForm);
                    },
                    /*uploadOrderFile(e) {
                        if(e.target.files.length > 0) {
                            this.orderFile = e.target.files.item(0).name;
                        } else {
                            this.orderFile = null;
                        }
                    },*/
                },
                created: function () {
                },
                destroyed: function () {
                }
            })
        )
    }
});
