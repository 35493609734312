Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("order-deliveryform")) {
        Global.components.push(
            new Vue({
                el: '#order-deliveryform',
                store,
                data: function () {
                    return {
                        hasCaptcha: false,
                        captchaExecuted: false,
                        unloading_parking: [],
                        technical_back_wall: null,
                    }
                },
                components: {
                    'vue-recaptcha': VueRecaptcha
                },
                computed: {
                },
                watch: {
                },
                created: function() {
                    let checkTechnicalBackWall = document.querySelector('input[name="technical_back_wall"]:checked');
                    if(checkTechnicalBackWall !== null) {
                        this.technical_back_wall = checkTechnicalBackWall.value;
                    }

                    let checkedUnloadingParking = document.querySelectorAll('input[name="unloading_parking[]"]:checked');
                    if(checkedUnloadingParking.length > 0) {
                        for(let x = 0 ; x < checkedUnloadingParking.length ; x++) {
                            this.unloading_parking.push(checkedUnloadingParking[x].value);
                        }
                    }
                },
                mounted: function () {
                },
                methods: {
                    submit: function () {
                        const that = this;
                        this.$validator.validateAll().then(result => {
                            let analytics = that.$el.dataset.analytics;

                            if (result) {
                                if (!this.hasCaptcha || this.captchaExecuted) {
                                    Global.SubmitForm(that.$el, analytics);
                                } else {
                                    this.$refs.recaptcha.execute();
                                }
                            }
                        });
                    },
                    onCaptchaVerified: function () {
                        this.captchaExecuted = true;
                        this.submit();
                    },
                    checkDates: function() {
                        let fromDate = (this.$refs.delivery_date_from.value != '' ? moment(this.$refs.delivery_date_from.value) : null);
                        let toDate = (this.$refs.delivery_date_to.value != '' ? moment(this.$refs.delivery_date_to.value) : null);

                        let minDate = moment(this.$refs.delivery_date_from.min);

                        if(fromDate !== null) {
                            // fromDate must always be at least the minDate
                            if (fromDate < minDate) {
                                fromDate = minDate;
                            }
                        }

                        if(toDate !== null) {

                            // toDate must always be at least the minDate
                            if (toDate < minDate) {
                                toDate = minDate;
                            }

                            // toDate must always be equal to or higher then fromDate
                            if (toDate < fromDate) {
                                toDate = fromDate;
                            }
                        }

                        // Write back the values
                        if(fromDate !== null) {
                            this.$refs.delivery_date_from.value = fromDate.format('YYYY-MM-DD');
                            this.$refs.delivery_date_to.min = fromDate.format('YYYY-MM-DD');
                        }

                        if(toDate !== null) {
                            this.$refs.delivery_date_to.value = toDate.format('YYYY-MM-DD');
                        }

                    }
                },
                destroyed: function () {
                }
            })
        )
    }
});
