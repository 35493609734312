Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("header")) {
        Global.components.push(
            new Vue({
                el: '#header',
                store,
                data: function () {
                    return {
                        // State
                        scrolled: false,
                        isScrollingDown: false,
                        previousScrollingPosition: window.pageYOffset || document.documentElement.scrollTop,
                        currentScrollingPosition: window.pageYOffset || document.documentElement.scrollTop,
                        inputFocused: false,
                        loadingResults: false,
                        queryKeyUpTimer: null,

                        // External sources
                        inspirationSearchUrl: '',
                        productSearchUrl: '',
                        knowledgebaseSearchUrl: '',

                        // Input data
                        searchQuery: '',
                        searchType: window.localStorage.getItem('searchType') ? window.localStorage.getItem('searchType') : 'inspiration',

                        // Data
                        searchResults: null,
                    }
                },
                watch: {
                    searchType(newValue, oldValue) {
                        if(newValue != oldValue) {
                            window.localStorage.setItem('searchType', newValue);
                            clearTimeout(this.queryKeyUpTimer);
                            this.getResults();
                            this.queryKeyUpTimer = null;
                        }
                    },
                    inputFocused(newValue) {
                        this.inSearchMode = newValue;
                    }
                },
                computed: {
                    menuExpanded: {
                        get: function () {
                            return this.$store.getters.menuExpanded
                        },
                        set: function (newValue) {
                            this.$store.dispatch('toggleMenu', newValue)
                        }
                    },
                    inSearchMode: {
                        get: function () {
                            return this.$store.getters.inSearchMode
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setInSearchMode', newValue)
                        }
                    },

                    showSearch: function() {

                        if(this.inputFocused && !(this.scrolled && this.isScrollingDown)) {
                            let url;

                            if (this.searchType === "inspiration") {
                                url = this.inspirationSearchUrl;
                            } else if (this.searchType === "products") {
                                url = this.productSearchUrl;
                            } else if (this.searchType === "knowledgebase") {
                                url = this.knowledgebaseSearchUrl;
                            }

                            return (window.location.href.indexOf(url) == -1);
                        }

                        return false;
                    }
                },
                mounted: function () {
                    this.resetExpandedMenu();

                    this.inspirationSearchUrl = this.$refs.searchBar ? this.$refs.searchBar.dataset.inspirationUrl : '';
                    this.productSearchUrl = this.$refs.searchBar ? this.$refs.searchBar.dataset.productUrl : '';
                    this.knowledgebaseSearchUrl = this.$refs.searchBar ? this.$refs.searchBar.dataset.knowledgebaseUrl : '';

                    this.searchQuery = (Global.getQueryStringParameter(window.location.href, 'query') || '');

                    window.addEventListener('scroll', () => {
                        this.watchScrollPosition();
                        this.watchIfScrollingUp();
                    });
                },
                methods: {
                    onQueryKeyUp() {
                        clearTimeout(this.queryKeyUpTimer);

                        let emitEvent = 'search' + this.searchType.substr(0,1).toUpperCase() + this.searchType.substr(1);

                        if (this.searchQuery.length > 1) {
                            this.queryKeyUpTimer = setTimeout(function () {
                                this.getResults();
                                Global.eventBus.$emit(emitEvent, this.searchQuery);
                                this.queryKeyUpTimer = null;
                            }.bind(this), 500);
                        } else {
                            this.clearResults();
                            Global.eventBus.$emit(emitEvent, '');
                            this.inSearchMode = false;
                        }
                    },
                    onQueryEnterPress() {
                        this.submitSearch();
                    },
                    onQueryFocus() {
                        this.inputFocused = true
                    },
                    resetExpandedMenu() {
                        this.menuExpanded = false;
                    },
                    toggleMenu() {
                        this.menuExpanded = !this.menuExpanded;
                        document.body.classList.toggle('body--overflowHidden');
                    },
                    watchScrollPosition() {
                        const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
                        this.scrolled = scrollTop > 50;
                    },
                    watchIfScrollingUp() {
                        this.currentScrollingPosition = window.pageYOffset || document.documentElement.scrollTop;
                        this.isScrollingDown = this.previousScrollingPosition < this.currentScrollingPosition;
                        this.previousScrollingPosition = this.currentScrollingPosition;
                    },
                    getResults() {
                        if (! this.loadingResults) {
                            this.loadingResults = true;
                            let url;

                            if (this.searchType === "inspiration") {
                                url = this.inspirationSearchUrl;
                            } else if (this.searchType === "products") {
                                url = this.productSearchUrl;
                            } else if (this.searchType === "knowledgebase") {
                                url = this.knowledgebaseSearchUrl;
                            }

                            url = Global.updateQueryStringParameter(url, 'query', this.searchQuery);

                            API.GET(url).then(response => {
                                this.loadingResults = false;
                                this.searchResults = response.data;
                            });
                        }
                    },
                    submitSearch() {
                        if (this.searchQuery.length > 1) {
                            if (this.searchType === "inspiration") {
                                this.searchInspiration();
                            } else if (this.searchType === "products") {
                                this.searchProducts();
                            } else if (this.searchType === "knowledgebase") {
                                this.searchKnowledge();
                            }
                        }
                    },
                    searchInspiration() {
                        let url = Global.updateQueryStringParameter(this.inspirationSearchUrl, 'query', this.searchQuery);
                        this.inSearchMode = false;
                        if (window.location.href.indexOf(this.inspirationSearchUrl) === -1) {
                            Barba.Pjax.goTo(url);
                            return false;
                        }

                        // anders alleen de url bijwerken // TODO
                        history.replaceState({}, window.location.title, url);
                    },
                    searchProducts() {
                        let url = Global.updateQueryStringParameter(this.productSearchUrl, 'query', this.searchQuery);
                        this.inSearchMode = false;
                        if (window.location.href.indexOf(this.productSearchUrl) === -1) {
                            Barba.Pjax.goTo(url);
                            return false;
                        }

                        // anders alleen de url bijwerken // TODO
                        history.replaceState({}, window.location.title, url);
                    },
                    searchKnowledge() {
                        let url = Global.updateQueryStringParameter(this.knowledgebaseSearchUrl, 'query', this.searchQuery);
                        this.inSearchMode = false;
                        if (window.location.href.indexOf(this.knowledgebaseSearchUrl) === -1) {
                            Barba.Pjax.goTo(url);
                            return false;
                        }

                        // anders alleen de url bijwerken // TODO
                        history.replaceState({}, window.location.title, url);
                    },
                    resetSearchQuery(url, searchType) {
                        this.searchQuery = '';

                        if(typeof(searchType) != 'undefined') {
                            window.localStorage.setItem('searchType', searchType);
                        }

                        if(typeof(url) != 'undefined') {
                            Barba.Pjax.goTo(url);
                        }
                    },
                    clearResults() {
                        this.searchResults = 0;
                        this.inSearchMode = false;
                    }
                },
                created: function () {
                    Global.eventBus.$on('resetProductSearch', this.resetSearchQuery);
                },
                destroyed: function () {
                }
            })
        )
    }
});
