Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("edit-floorplans-modal")) {
        Global.components.push(
            new Vue({
                el: '#edit-floorplans-modal',
                store,
                mixins: [manageFloorplans],
                data: function () {
                    return {
                    }
                },
                computed: {
                    showEditFloorplansModal: {
                        get: function () {
                            return this.$store.getters.showEditFloorplansModal
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setShowEditFloorplansModal', newValue);
                        }
                    },
                    messageText: {
                        get: function () {
                            return this.$store.getters.messageText
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setMessageText', newValue)
                        }
                    }
                },
                watch: {
                    showEditFloorplansModal: function (newValue) {
                        if (newValue) {
                            this.getUploadedFileList();
                        } else {
                            this.uploadedFileList = [];
                        }
                    },
                },
                methods: {
                },
                created: function () {
                },
                destroyed: function () {
                }
            })
        )
    }
});
