Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("message-error")) {
        Global.components.push(
            new Vue({
                el: '#message-error',
                store,
                data: function () {
                    return {
                        validationErrors: {},
                    }
                },
                computed: {
                    data: {
                        get: function () {
                            return this.$store.getters.errorObject
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setErrorObject', newValue)
                        }
                    },

                    show: {
                        get: function () {
                            return this.$store.getters.showError
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setShowError', newValue)
                        }
                    },
                },
                watch: {
                    data: function (val, oldval) {
                        const that = this;

                        if (val !== null && val !== undefined) {
                            that.validationErrors = val.errors;
                            that.show = true;
                        } else {
                            that.validationErrors = {};
                            that.show = false;
                        }
                    },
                    show: function (val, oldVal) {
                        if (!val) {
                            this.data = null;
                        }
                    }
                },
                mounted: function () {
                },
                methods: {
                    close: function () {
                        const that = this;
                        this.show = false;

                        window.setTimeout(function () {
                            that.data = null;
                        }, 400);
                    }
                },
                created: function () {
                },
                destroyed: function () {
                }
            })
        )
    }
});