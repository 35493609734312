Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("profile-image")) {
        Global.components.push(
            new Vue({
                el: "#profile-image",
                store,
                data: function () {
                    return {
                        imageUrl: ''
                    }
                },
                computed: {},
                mounted: function () {
                    this.setImage();
                },
                methods: {
                    setImage: function () {
                        this.$refs.image.src = this.$refs.image.dataset.src;
                    },
                    formChanged: function () {
                        this.submit();
                    },
                    submit: function () {
                        const that = this;
                        this.$validator.validateAll().then(result => {
                            let analytics = that.$el.dataset.analytics;

                            if (result) {
                                Global.SubmitForm(that.$el, analytics).then(function (response) {
                                    that.imageUrl = response.image;
                                });
                            }
                        });
                    }
                },
                created: function () {
                },
                destroyed: function () {
                }
            })
        )
    }
});