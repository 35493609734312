let PagingStorageMixin = {
    data() {
        return {
            loadingData: false,
            data: null,
            searchQuery: null,
            storageEnabled: false,
            storageKey: null,
        }
    },
    created() {

        // Try to get the search query
        let searchQuery = Global.getQueryStringParameter(window.location.href, 'query');
        if (searchQuery !== '' && searchQuery !== null && searchQuery !== undefined) {
            this.searchQuery = searchQuery;
        }

        // Try to load previous content from storage
        this.loadDataFromStorage();
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll);

        this.loadScrollFromStorage();
    },
    destroyed() {
        window.removeEventListener('scroll', this.onScroll);
    },
    methods: {
        getData(url, append, callback) {

            const that = this;

            if(typeof(append) == 'undefined') {
                append = true;
            }

            if(typeof(callback) == 'undefined') {
                callback = null;
            }

            that.loadingData = true;
            API.GET(url).then(response => {
                if(typeof(response) == 'object') {
                    if(append && that.data !== null) {
                        for(var a in response) {
                            if((a == 'data' || a == 'layoutIndex') && typeof(that.data[a]) != 'undefined') {
                                that.data[a] = that.data[a].concat(response[a]);
                            } else {
                                that.data[a] = response[a];
                            }
                        }
                    } else {
                        that.data = response;
                    }

                    // Store data in storage
                    that.saveDataInStorage();

                    // Callback
                    if(callback != null) {
                        callback.call(that, response);
                    }

                    that.loadingData = false;
                }
            });
        },
        buildStorageKey() {
            let key = this.storageKey
                + '/' + window.location.pathname.replace(/\//g,'-')
                + '/' + this.searchQuery
                + '/' + this.currentPage;
            return key;
        },
        saveDataInStorage() {
            let storage = (typeof(window.sessionStorage) !== "undefined" ? window.sessionStorage : false);
            if(this.storageEnabled && storage !== false) {
                let key = this.buildStorageKey();
                storage.setItem(key, JSON.stringify({
                    timestamp: new Date().getTime(),
                    meta: {
                        page: this.currentPage,
                        query: this.searchQuery,
                        scroll: window.scrollY,
                    },
                    data: this.data
                }));
            }
        },
        loadDataFromStorage() {
            let storage = (typeof(window.sessionStorage) !== "undefined" ? window.sessionStorage : false);
            if(this.storageEnabled && storage !== false) {

                let restoreItem = false;

                // First check for restore=1, if present restore the latest data entry
                let restore = parseInt(Global.getQueryStringParameter(window.location.href, 'restore'));
                if(!isNaN(restore) && restore == 1) {
                    if(storage.length > 0) {
                        for(let s = 0 ; s < storage.length ; s++) {
                            let item = storage.getItem(storage.key(s));
                            if(item !== null) {
                                let itemData = JSON.parse(item);
                                if (itemData !== null && itemData !== false && itemData.hasOwnProperty('timestamp')) {
                                    if(restoreItem == false || itemData.timestamp > restoreItem.timestamp) {
                                        restoreItem = itemData;
                                    }
                                }
                            }
                        }
                    }
                }

                if(restoreItem !== false) {
                    // Restore data
                    this.data = restoreItem.data;


                    // Replace the current url
                    let url = window.location.href;

                    // Remove restore
                    url = Global.removeQueryStringParameter(url, 'restore');

                    // Append the page
                    url = Global.updateQueryStringParameter(url, 'page', this.currentPage);

                    // Append the search query
                    if (restoreItem.meta.query !== '' && restoreItem.meta.query !== null && restoreItem.meta.query !== undefined) {
                        url = Global.updateQueryStringParameter(url, 'query', restoreItem.meta.query);
                    }


                    window.history.replaceState({}, document.title, url);

                } else {
                    // Regular check, for when page or query query string is present
                    let key = this.buildStorageKey();
                    let item = storage.getItem(key);
                    if(item !== null) {
                        let itemData = JSON.parse(item);
                        if(itemData !== null && itemData !== false && itemData.hasOwnProperty('data')) {
                            this.data = itemData.data;
                        } else {
                            storage.removeItem(key);
                        }
                    }
                }
            }
        },
        saveScrollInStorage() {
            let storage = (typeof(window.sessionStorage) !== "undefined" ? window.sessionStorage : false);
            if(this.storageEnabled && storage !== false) {
                let key = this.buildStorageKey();
                let item = storage.getItem(key);
                if(item !== null) {
                    // Update
                    let itemData = JSON.parse(item);
                    if(itemData !== null && itemData !== false && itemData.hasOwnProperty('data')) {
                        itemData.meta.scroll = window.scrollY;
                        storage.setItem(key, JSON.stringify(itemData));
                    } else {
                        this.saveDataInStorage();
                    }
                } else {
                    // Insert
                    this.saveDataInStorage();
                }
            }
        },
        loadScrollFromStorage() {
            let storage = (typeof(window.sessionStorage) !== "undefined" ? window.sessionStorage : false);
            if(this.storageEnabled && storage !== false) {
                let key = this.buildStorageKey();
                let item = storage.getItem(key);
                if (item !== null) {
                    let itemData = JSON.parse(item);
                    if (itemData !== null && itemData !== false && itemData.hasOwnProperty('meta') && itemData.meta.hasOwnProperty('scroll')) {
                        let scrollTo = itemData.meta.scroll;
                        window.scrollTo(0, scrollTo);
                    }
                }
            }
        },
        onScroll() {
            this.saveScrollInStorage();
        },
    },
    computed: {
        items() {
            if(this.data !== null && this.data !== undefined && this.data.hasOwnProperty('data')) {
                return this.data.data;
            }
            return null
        },
        meta() {
            if(this.data !== null && this.data !== undefined  && this.data.hasOwnProperty('meta')) {
                return this.data.meta;
            }
            return null
        },
        currentPage() {
            let fromMeta = parseInt((this.meta !== null ? this.meta['current_page'] : null));
            let fromUrl = parseInt(Global.getQueryStringParameter(window.location.href, 'page'));

            if(!isNaN(fromMeta)) {
                return fromMeta;
            } else if(!isNaN(fromUrl)) {
                return fromUrl;
            }

            return 1;
        },
        nextPage() {
            return (this.currentPage + 1);
        },
        showLoadMore() {
            return (this.meta !== null ? this.meta['last_page'] >= this.nextPage : false);
        },
    }
};
