Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("order-blocked-button")) {
        Global.components.push(
            new Vue({
                el: '#order-blocked-button',
                store,
                data: function () {
                    return {
                    }
                },
                computed: {
                    showMessage: {
                        get: function () {
                            return this.$store.getters.showOrderBlockedMessage
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setShowOrderBlockedMessage', newValue)
                        }
                    }
                },
                methods: {
                    onClick() {
                        this.showMessage = true;
                    }
                },
            })
        )
    }
});
