Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("board-overview")) {
        Global.components.push(
            new Vue({
                el: '#board-overview',
                store,
                data: function () {
                    return {
                        showCreateBoard: false,
                        boardTypeUrl: null,
                        url: {},
                        meta: {},
                        loading: {}
                    }
                },
                computed: {
                    activeBoardFilter: {
                        get: function () {
                            return this.$store.getters.activeBoardFilter
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setActiveBoardFilter', newValue)
                        }
                    },
                    showArchive: {
                        get: function () {
                            return this.$store.getters.showBoardArchive
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setShowBoardArchive', newValue);
                        }
                    },
                    moodboardItems: {
                        get: function () {
                            return this.processArchive(this.$store.getters.moodboardItems);
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setMoodboardItems', newValue)
                        }
                    },
                    projectItems: {
                        get: function () {
                            return this.processArchive(this.$store.getters.projectItems);
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setProjectItems', newValue)
                        }
                    },
                    creationItems: {
                        get: function () {
                            return this.processArchive(this.$store.getters.creationItems);
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setCreationItems', newValue)
                        }
                    },
                    messageText: {
                        get: function () {
                            return this.$store.getters.messageText
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setMessageText', newValue)
                        }
                    },
                },
                watch: {
                    moodboardItems: function () {
                        this.$nextTick(() => {
                            Global.BlazyInstance.revalidate();
                        })
                    },
                    projectItems: function () {
                        this.$nextTick(() => {
                            Global.BlazyInstance.revalidate();
                        })
                    },
                    creationItems: function () {
                        this.$nextTick(() => {
                            Global.BlazyInstance.revalidate();
                        })
                    }
                },
                created: function () {
                },
                mounted: function () {
                    this.loadData();
                    window.addEventListener('scroll', this.loadMoreOnScroll);
                },
                destroyed: function () {
                    window.removeEventListener('scroll', this.loadMoreOnScroll);
                },
                methods: {
                    loadData: function() {
                        const that = this;

                        let lists = this.$el.querySelectorAll('.boards-overview');
                        if (!lists.length) {
                            return false;
                        }

                        // loop over de lijsten heen
                        lists.forEach(list => {
                            let data = list.dataset;
                            let type = data.type;

                            if (! data.hasOwnProperty('url')) {
                                return true;
                            }

                            that.url[type] = data.url;
                            that.loading[type] = true;

                            // haal de data op
                            let url = data.url;
                            API.GET(url).then(response => {
                                if (! response.hasOwnProperty('data')) {
                                    return false;
                                }

                                // stop de items in de goede var
                                if (data.type === 'projects') {
                                    that.projectItems = response.data;
                                } else if (data.type === 'moodboards') {
                                    that.moodboardItems = response.data;
                                } else if (data.type === 'creations') {
                                    that.creationItems = response.data;
                                }

                                // globale vars
                                that.loading[type] = false;
                                that.meta[type] = response.meta;
                            });
                        });
                    },
                    loadMore(type) {
                        const that = this;

                        if (!this.loading[type]) {

                            if (this.meta[type].last_page > this.meta[type].current_page) {
                                this.loading[type] = true;
                                let url = that.url[type];

                                url = Global.updateQueryStringParameter(url, 'page', ++this.meta[type].current_page);

                                API.GET(url).then(response => {

                                    // stop de items in de goede var
                                    if (type === 'projects') {
                                        that.projectItems = that.projectItems.concat(response.data);
                                    } else if (type === 'moodboards') {
                                        that.moodboardItems = that.moodboardItems.concat(response.data);
                                    } else if (type === 'creations') {
                                        that.creationItems = that.creationItems.concat(response.data);
                                    }

                                    this.loading[type] = false;
                                });
                            }
                        }
                    },
                    loadMoreOnScroll() {
                        const that = this;
                        let lists = that.$el.querySelectorAll('.boards-overview');
                        if (!lists.length) {
                            return false;
                        }

                        lists.forEach(list => {
                            const _this = that;
                            const _list = list;
                            let data = _list.dataset;
                            let type = data.type;

                            if (! data.hasOwnProperty('url')) {
                                return true;
                            }

                            if (!_this.loading[type]) {

                                let overviewBoundary = _list.getBoundingClientRect();

                                if (window.innerHeight > (overviewBoundary.height + overviewBoundary.top)) {
                                    _this.loadMore(type);
                                }
                            }
                        });
                    },
                    createBoard: function () {
                        const that = this;

                        this.$validator.validateAll().then(result => {
                            if (result) {
                                API.POST(that.boardTypeUrl, new FormData(this.$refs.createBoardForm)).then(response => {
                                    that.messageText = response.message;
                                    Barba.Pjax.goTo(response.target);
                                });
                            }
                        });
                    },
                    processArchive: function (items) {
                        if(typeof(items) != 'undefined' && items != null && items.length > 0) {
                            return items.filter((item) => {
                               return (this.showArchive || (!this.showArchive && !item.archived));
                            });
                        }

                        return items;
                    },
                }
            })
        )
    }
});
