Vue.component('editable-price', {
    props: ['class-prefix', 'modified', 'value', 'default'],
    data: function () {
        return {
            inputValue: 0,
            allowedCharactersSingle: [ ',' ],
            allowedCharactersDouble: [ '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ',' ]
        }
    },
    methods: {
        prefixClass(value) {

            let prefix = (this.classPrefix != null && this.classPrefix != '' ? this.classPrefix + '__' : '');
            if(prefix == '') {
                return value;
            }

            if(typeof(value) == 'string') {
                return prefix + value;
            } else if(typeof(value) == 'object') {
                let classNames = {};
                for(var a in value) {
                    classNames[prefix + a] = value[a];
                }
                return classNames;
            }

        },
        className(name) {
            if(this.classPrefix != null && this.classPrefix != '') {
                return this.classPrefix + '__' + name;
            }

            return name;
        },
        minLength(value, min) {
            if(isNaN(value) || value < min) {
                return min;
            }

            return value;
        },
        inputWidthAsCh() {
            return this.minLength((this.inputValue != null ? this.inputValue.length+0.5 : 0), 3.5) + 'ch';
        },
        stripInputValue() {
            let regexp = new RegExp('[^' + this.allowedCharactersSingle.concat(this.allowedCharactersDouble).join('') + ']', 'gi');
            this.inputValue = this.inputValue.replace(regexp, '');
        },
        onInput() {
            this.stripInputValue();

          this.$emit('input', this.inputValue);
        },
        onValueKeyUp(event) {

            this.stripInputValue();

            this.$emit('keyup', {
                modified: this.modified,
                value: this.inputValue
            });
        },
        onValueKeyDown(event) {
            if(this.isShiftKey(event)){
                event.preventDefault();
                return false;
            } else if(!this.isBackspaceKey(event) && !this.isPasteEvent(event) && !this.isArrowKey(event)) {

                // Prevent if:
                // - Character is not allowed at all
                if(this.allowedCharactersSingle.concat(this.allowedCharactersDouble).indexOf(event.key) < 0) {
                    event.preventDefault();
                    return false;
                }
                // - Character is not allowed double
                if(this.allowedCharactersSingle.indexOf(event.key) >= 0 && event.target.value.indexOf(event.key) >= 0) {
                    event.preventDefault();
                    return false;
                }
            }

            this.stripInputValue();

            this.$emit('keydown', {
                modified: this.modified,
                value: this.inputValue
            });
        },
        onClickModify() {
            this.inputValue = this.default;
            this.$emit('input', this.default);

            this.$emit('modify', {
                modified: true,
                value: this.default
            });

            this.$refs.input.focus();
        },
        onClickReset() {
            this.inputValue = this.default;
            this.$emit('input', null);

            this.$emit('modify', {
                modified: false,
                value: null
            });

            this.$refs.input.blur();
        },
        isPasteEvent: function(event) {
            return ((event.ctrlKey || event.metaKey) && event.keyCode == 86);
        },
        isShiftKey: function(event){
            return (event.shiftKey)
        },
        isBackspaceKey: function(event) {
            return (event.keyCode == 8);
        },
        isArrowKey: function(event) {
            return (event.keyCode == 37 || event.keyCode == 39);
        },
        isTabKey: function (event) {
            return (event.keyCode == 9 || event.keyCode == 9 && event.shiftKey)
        },
    },
    mounted() {
        this.inputValue = (this.modified ? this.value : this.default);
    },
    destroyed() {
    },
    template:
        '<div :class="classPrefix">' +
            '<div :class="prefixClass(\'icon\')">\n' +
                '<button type="button" :class="prefixClass(\'button\')" v-if="modified" @click="onClickReset()">' +
                    '<svg width="32" height="32" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M704 1376v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm-544-992h448l-48-117q-7-9-17-11h-317q-10 2-17 11zm928 32v64q0 14-9 23t-23 9h-96v948q0 83-47 143.5t-113 60.5h-832q-66 0-113-58.5t-47-141.5v-952h-96q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h309l70-167q15-37 54-63t79-26h320q40 0 79 26t54 63l70 167h309q14 0 23 9t9 23z"/></svg>' +
                '</button>\n' +
                '<button type="button" :class="prefixClass(\'button\')" v-if="!modified" @click="onClickModify()">' +
                    '<svg id="Laag_1" data-name="Laag 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.05 27.05"><title>pencil</title><path d="M8.77,27.43l1.62-1.63L6.2,21.61,4.57,23.23v1.91H6.86v2.29Zm9.34-16.57a.35.35,0,0,0-.4-.4.43.43,0,0,0-.3.13L7.73,20.27a.41.41,0,0,0-.12.3A.34.34,0,0,0,8,21a.41.41,0,0,0,.3-.12L18,11.16A.38.38,0,0,0,18.11,10.86Zm-1-3.43,7.43,7.43L9.71,29.71H2.29V22.29Zm12.2,1.71a2.2,2.2,0,0,1-.66,1.61l-3,3L18.29,6.29l3-2.95a2.13,2.13,0,0,1,1.61-.68,2.21,2.21,0,0,1,1.62.68l4.2,4.18A2.27,2.27,0,0,1,29.34,9.14Z" transform="translate(-2.29 -2.66)" fill="#163517"/></svg>' +
                '</button>\n' +
            '</div>\n' +
            '<div :class="prefixClass(\'input\')">\n' +
                '<label :class="prefixClass(\'input-label\')">\n' +
                    '<span>€</span>\n' +
                    '<input type="text" ' +
        ':class="prefixClass(\'input-text\')" ' +
        'v-model="inputValue" ' +
        ':style="{width: inputWidthAsCh() }" ' +
        '@input="onInput()" ' +
        '@keyup="onValueKeyUp($event)" ' +
        '@keydown="onValueKeyDown($event)" ' +
        ':readonly="!modified" ref="input"/>' +
                '</label>\n' +
            '</div>' +
        '</div>'
});

/**
 *
 */
