const manageFloorplans = {
    data: function () {
        return {
            fileList: [],
            parsedFileList: [],
            uploadedFileList: [],
            uploadFloorplanUrl: document.getElementById("floorplan-ui").dataset.uploadFloorplanUrl,
            confirmUploadFloorplansUrl: document.getElementById("floorplan-ui").dataset.confirmUploadFloorplansUrl,
            getFloorplanUrl: document.getElementById("floorplan-ui").dataset.getFloorplanUrl,
            uploadIndex: 0,
            formDataToUpload: new FormData(),
            uploadingInProgress: 0,
            uploadingConfirmationInProgress: 0
        }
    },
    computed: {
        floorplans: {
            get: function () {
                return this.$store.getters.projectFloorplans;
            },
            set: function (newValue) {
                this.$store.dispatch('setProjectFloorplans', newValue)
            }
        },
        activeFloorplan: {
            get: function () {
                return this.$store.getters.activeFloorplan
            },
            set: function (newValue) {
                this.$store.dispatch('setActiveFloorplan', newValue);
            }
        }
    },
    watch: {
        fileList: function (newValue) {
            this.parsedFileList = [];
            for (let i = 0; i < newValue.length; i++) {
                this.parsedFileList.push(newValue.item(i));
            }
        },
        floorplans: function (newValue) {
            if (newValue !== null && newValue.length) {
                this.getUploadedFileList();
            } else {
                this.uploadedFileList = [];
            }
        }
    },
    methods: {
        /*
        * Haal de plattegronden op
        */
        getFloorplans: function () {
            // Hier worden de floorplans opgehaald om deze te kunnen weergeven in de plattegronden lijst.
            API.GET(this.getFloorplanUrl).then(data => {
                this.floorplans = data.data;
            });
        },
        /*
        * Haal de plattegronden op
        */
        processFile(event) {
            this.fileList = event.target.files;

            for (let i = 0; i < this.fileList.length; i++) {
                let formData = new FormData();
                formData.append('file', this.fileList.item(i));
                this.uploadFloorplan(formData);
            }
        },
        /*
         * Haal de geuploade plattegronden op, en voeg ze toe aan de lijst.
        */
        getUploadedFileList() {
            this.uploadedFileList = [];

            for (let i = 0; i < this.floorplans.length; i++) {
                this.uploadedFileList.push({
                    id: this.floorplans[i].id,
                    title: this.floorplans[i].title,
                    hash: this.floorplans[i].hash,
                    image: this.floorplans[i].image,
                    pointers: this.floorplans[i].pointers,
                    position: i
                });
            }
        },
        /*
        * de daadwerkeljke api call om een afbeelding te uploaden.
        * */
        uploadFloorplan(data) {
            this.uploadingInProgress++;

            API.POST(this.uploadFloorplanUrl, data).then(response => {
                this.uploadingInProgress--;

                response.data.forEach(item => {
                    let file = {
                        'title': item.title,
                        'hash': item.id,
                        'position': this.uploadIndex
                    };

                    ++this.uploadIndex;
                    this.uploadedFileList.push(file);
                });
            }).catch(error => {
                this.uploadingInProgress--;
            });
        },
        /*
        * Sla de geuploade afbeeldingen op.
        * */
        confirmFloorplansUpload() {
            this.formDataToUpload.append('_method', 'put');
            this.uploadingConfirmationInProgress++;

            if(this.uploadedFileList.length > 0) {
                for (let i = 0; this.uploadedFileList.length > i; i++) {
                    this.formDataToUpload.append('floorplans[' + i + '][title]', this.uploadedFileList[i].title);
                    this.formDataToUpload.append('floorplans[' + i + '][position]', this.uploadedFileList[i].position);
                    this.formDataToUpload.append('floorplans[' + i + '][hash]', this.uploadedFileList[i].hash);
                }
            } else {
                this.formDataToUpload.append('floorplans', 'destroy');
            }

            API.POST(this.confirmUploadFloorplansUrl, this.formDataToUpload).then(response => {
                this.uploadingConfirmationInProgress--;
                this.messageText = response.message;
                this.getFloorplans();
                this.showEditFloorplansModal = false;
            }).catch(error => {
                this.uploadingConfirmationInProgress--;
            });
        },
        /*
        * Verwijder de plattegrond
        * */
        deleteFloorplan(index) {
            let change = (this.uploadedFileList.length ** (this.activeFloorplan.id === this.uploadedFileList[index].id));

            this.uploadedFileList.splice(index, 1);

            if (change && this.uploadedFileList.length) {
                this.activeFloorplan = this.uploadedFileList[0];
            }
        },
        /*
        * Wijzig de volgorde van de plattegronden
        * */
        moveFileUp(index) {
            let newIndex = index--;
            this.uploadedFileList = Global.array_move(this.uploadedFileList, index, newIndex);

            for (let i = 0; this.uploadedFileList.length > i; i++) {
                this.uploadedFileList[i].position = i;
            }
        },
        /*
        * Wijzig de volgorde van de plattegronden
        * */
        moveFileDown(index) {
            let newIndex = index++;
            this.uploadedFileList = Global.array_move(this.uploadedFileList, index, newIndex);

            for (let i = 0; this.uploadedFileList.length > i; i++) {
                this.uploadedFileList[i].position = i;
            }
        }
    }
};
