Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("delete-board-or-project")) {
        Global.components.push(
            new Vue({
                el: '#delete-board-or-project',
                store,
                data: function () {
                    return {
                        redirectUrl: '/dashboard/',
                        confirmMessage: 'Weet je het zeker?'
                    }
                },
                mounted: function () {
                },
                computed: {
                    messageText: {
                        get: function () {
                            return this.$store.getters.messageText
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setMessageText', newValue)
                        }
                    },
                    showMessage: {
                        get: function () {
                            return this.$store.getters.showMessage
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setShowMessage', newValue)
                        }
                    }
                },
                watch: {},
                methods: {
                    confirm: function () {
                        if (confirm(this.$el.dataset.confirmMessage || this.confirmMessage) !== true) {
                            return false;
                        }

                        const that = this;

                        let data = new FormData();
                        data.append('_method', 'delete');

                        API.POST(this.$el.dataset.url, data).then(response => {
                            that.messageText = response.message;
                            Barba.Pjax.goTo(that.$el.dataset.redirectUrl || that.redirectUrl);
                        });

                    }
                },
                created: function () {
                },
                destroyed: function () {
                }
            })
        )
    }
});
