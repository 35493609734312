Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("discussion")) {
        Global.components.push(
            new Vue({
                el: '#discussion',
                store,
                data: function () {
                    return {
                        newComment: '',
                        commentsUrl: document.getElementById("discussion").dataset.commentsUrl,
                        commentInstanceId: document.getElementById("discussion").dataset.commentInstanceId,
                        oldComments: [],
                        comments: [],
                        guest: !!document.getElementById("discussion").dataset.guest,
                        guestName: '',
                        guestEmail: '',
                        showGuestField: false,
                        inViewport: false,
                        notificationMessage: document.getElementById("discussion").dataset.notification,
                        interval: 5000,
                        intervalTimer: false
                    }
                },
                computed: {
                    newCommentsCount: function () {
                        return this.comments.length - this.oldComments.length;
                    },
                    messageText: {
                        get: function () {
                            return this.$store.getters.messageText
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setMessageText', newValue)
                        }
                    },
                    showMessage: {
                        get: function () {
                            return this.$store.getters.showMessage
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setShowMessage', newValue)
                        }
                    }
                },
                watch: {
                    oldComments: function (oldValue, newValue) {
                        if (newValue.length) {
                            window.localStorage.setItem('comments-' + this.commentInstanceId, JSON.stringify(newValue));
                        }
                    },
                    comments: function (newValue, oldValue) {
                        this.$nextTick(function () {
                            if (oldValue.length !== newValue.length) {
                                this.$refs.discussionList.scrollTop = this.$refs.discussionList.scrollHeight;

                                let hasMessage = (this.notificationMessage != null);
                                let hadComments = (oldValue.length > 0);
                                let hasComments = (newValue.length > 0);
                                let userIsGuest = this.guest;
                                let userIsWatching = this.inViewport;
                                let lastCommentWasMine = false;

                                if(hasComments) {
                                    let lastComment = newValue[(newValue.length - 1)];
                                    if(lastComment.hasOwnProperty('me')) {
                                        lastCommentWasMine = lastComment.me;
                                    }
                                }

                                let showNotification = (hasMessage && hadComments && hasComments && !userIsGuest && !userIsWatching && !lastCommentWasMine);

                                if(showNotification) {
                                    this.messageText = this.notificationMessage;
                                }
                            }
                        });
                    },
                },
                created: function () {
                },
                mounted: function () {
                    const that = this;

                    if (window.localStorage.getItem('comments-' + this.commentInstanceId) !== null) {
                        this.oldComments = JSON.parse(window.localStorage.getItem('comments-' + this.commentInstanceId));
                    }

                    if (window.localStorage.getItem('guestName') !== null) {
                        this.guestName = window.localStorage.getItem('guestName');
                        Global.setCookie("guestName", this.guestName, 365);
                    }

                    if (window.localStorage.getItem('guestEmail') !== null) {
                        this.guestEmail = window.localStorage.getItem('guestEmail');
                        Global.setCookie("guestEmail", this.guestEmail, 365);
                    }

                    that.getComments();
                },
                destroyed: function () {
                    if(this.intervalTimer) {
                        clearTimeout(this.intervalTimer);
                    }
                },
                methods: {
                    getComments: function () {
                        const that = this;

                        if(that.intervalTimer) {
                            clearTimeout(that.intervalTimer);
                        }

                        API.GET(this.commentsUrl).then(response => {
                            that.comments = response.data;
                            that.oldComments = response.data;

                            if(that.interval && that.interval > 0) {
                                that.intervalTimer = setTimeout(that.getComments, that.interval);
                            }
                        });
                    },
                    addComment: function () {
                        if ((!this.guest || this.guestName.length) && this.newComment.length) {

                            const that = this;

                            if(that.intervalTimer) {
                                clearTimeout(that.intervalTimer);
                            }

                            let formData = new FormData();
                            formData.append('body', this.newComment);

                            if (this.guest) {
                                formData.append('guest', this.guestName);
                                formData.append('guest_email', this.guestEmail);
                            }

                            this.newComment = '';
                            this.showGuestField = false;

                            API.POST(this.commentsUrl, formData).then(response => {
                                that.comments = response.data;
                                that.oldComments = response.data;

                                if(that.interval && that.interval > 0) {
                                    that.intervalTimer = setTimeout(that.getComments, that.interval);
                                }
                            });
                        } else if (this.guest) {
                            this.showGuestField = true;
                        }
                    },
                    validateGuest: function() {
                        this.$validator.validateAll().then(result => {
                            if(result) {
                                window.localStorage.setItem('guestName', this.guestName);
                                Global.setCookie("guestName", this.guestName, 365);

                                window.localStorage.setItem('guestEmail', this.guestEmail);
                                Global.setCookie("guestEmail", this.guestEmail, 365);

                                this.addComment();
                            }
                        });
                    },
                    visibilityChanged: function (isVisible, entry) {
                        this.inViewport = isVisible;
                    }
                },


            })
        )
    }
});
