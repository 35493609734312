Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("product-config-step-2")) {
        Global.components.push(
            new Vue({
                el: document.getElementById("product-config-step-2"),
                store,
                data: function () {
                    return {
                        submitUrl: window.location.origin + '/dashboard/configurator',
                        showFilters: true,
                        loadingResults: false,
                        searchQuery: '',
                        filters: [],
                        filterValues: [],
                        products: [],
                        rawResponse: null,
                        options: {},
                        imageTitle: '',
                        dimensions: {},
                        submitFormStepAllowed: false,
                        perPage: 20,
                        perPageOptions: [20, 50, 100, 200],
                    }
                },
                computed: {
                    configurator: {
                        get() {
                            return this.$store.state.configurator;
                        },
                        set: function (value) {
                            this.$store.dispatch('setConfigurator', value)
                        }
                    },
                    configuratorStep2SubmitFormStepAllowed: {
                        get() {
                            return this.$store.state.configuratorStep2SubmitFormStepAllowed;
                        },
                        set: function (value) {
                            this.$store.dispatch('setConfiguratorStep2SubmitFormStepAllowed', value)
                        }
                    },
                    configuratorExternalSubmit: {
                        get() {
                            return this.$store.state.configuratorExternalSubmit;
                        },
                        set: function (value) {
                            this.$store.dispatch('setConfiguratorExternalSubmit', value)
                        }
                    },
                    filtersUrl() {
                        let url = window.location.origin + '/dashboard/configurator/' + this.configurator.id + '/filters';
                        url = Global.updateQueryStringParameter(url, 'type', 'container');

                        return url;
                    },
                    productsUrl() {
                        let url = window.location.origin + '/dashboard/configurator/' + this.configurator.id + '/products';
                        url = Global.updateQueryStringParameter(url, 'type', 'container');
                        url = Global.updateQueryStringParameter(url, 'query', this.searchQuery);
                        url = Global.updateQueryStringParameter(url, 'per_page', this.perPage);

                        this.filterValues.forEach(filter => {
                            if (typeof filter == 'object' && typeof filter.min != 'undefined') {
                                url = Global.updateQueryStringParameter(url, 'filter[' + filter.id + '][min]', filter.min);
                                url = Global.updateQueryStringParameter(url, 'filter[' + filter.id + '][max]', filter.max);
                            } else if(Array.isArray(filter)) {
                                url = Global.updateQueryStringParameter(url, 'filter[][' + filter[0] + ']', filter[1]);
                            }
                        });

                        return url;
                    }
                },
                watch: {
                    configurator: {
                        handler(newValue, oldValue) {
                            if (newValue.step > oldValue.step && newValue.id) {
                                window.scrollTo(0, 0);
                                this.fetchProducts(true);
                            }
                        },
                        deep: true
                    },
                    dimensions: {
                        handler(value) {
                            this.formSubmitAllowed();
                            this.configurator.container.dimensions = value;
                        },
                        deep: true
                    },
                    options: {
                        handler(value) {
                            this.configurator.container.options = value;
                        },
                        deep: true
                    },
                    configuratorExternalSubmit: {
                        handler(value) {
                            if(value == 2) {
                                this.submitStep();
                                this.configuratorExternalSubmit = null;
                            }
                        }
                    }
                },
                mounted: function () {
                    this.getFilters();
                },
                methods: {
                    submitStep() {
                        const that = this;
                        let data = JSON.parse(JSON.stringify(this.configurator));
                        ++data.step;

                        API.PUT(this.submitUrl + '/' + this.configurator.id, data).then(response => {
                            that.configurator = response.data;
                        });
                    },
                    getFilters() {
                        const that = this;

                        API.GET(this.filtersUrl).then(response => {
                            this.filters = response.data;

                            that.$nextTick(() => {
                                that.fetchProducts(true);
                            })
                        });
                    },
                    clearFilters() {
                        this.filterValues = [];
                        this.fetchProducts(false);
                    },
                    fetchProducts(showFilter = false, page = null) {
                        if (this.configurator.id) {
                            this.loadingResults = true;

                            let url = this.productsUrl;

                            if(typeof(page) != 'undefined' && !isNaN(parseInt(page))) {
                                url = Global.updateQueryStringParameter(url, 'page', page);
                            }

                            API.GET(url).then(response => {
                                this.products = response.data;
                                this.rawResponse = response;
                                this.showFilters = showFilter;
                                this.loadingResults = false;
                            });
                        }
                    },
                    fetchProductsPage(page) {
                        this.fetchProducts(false, page);
                    },
                    addProduct(product) {
                        const that = this;

                        let url = this.submitUrl + '/' + this.configurator.id;

                        this.configurator.container = {
                            type: 'product',
                            id: product.id
                        };

                        API.PUT(url, this.configurator).then(response => {
                            that.configurator = response.data;
                        });
                    },
                    uploadContainerImage(e) {
                        const that = this;
                        this.uploadingInProgress = true;

                        let formData = new FormData();
                        formData.append('file', e.target.files.item(0));

                        API.POST('/dashboard/upload/temporary', formData).then(response => {
                            that.uploadingInProgress = false;
                            that.imageTitle = response.data.title;
                            that.configurator.container.title = response.data.title;
                            that.configurator.container.upload = response.data.id;
                        });
                    },
                    formSubmitAllowed() {
                        if (this.configurator.container.shape === 'rectangle' || this.configurator.container.shape === 'square') {
                            this.submitFormStepAllowed =
                                (
                                    this.configurator.container.dimensions &&
                                    this.configurator.container.dimensions.width &&
                                    this.configurator.container.dimensions.length &&
                                    this.configurator.container.dimensions.depth
                                );

                        } else if (this.configurator.container.shape === 'circle') {
                            this.submitFormStepAllowed =
                                (
                                    this.configurator.container.dimensions &&
                                    this.configurator.container.dimensions.diameter &&
                                    this.configurator.container.dimensions.depth
                                );
                        } else {
                            this.submitFormStepAllowed = false;
                        }

                        this.configuratorStep2SubmitFormStepAllowed = this.submitFormStepAllowed;
                    },
                    gotoStep2() {
                        const that = this;

                        if (this.configurator.step > 2) {
                            this.configurator.contents = this.configurator.contents.filter(elem => {
                                return elem.type === 'container'
                            });

                            this.configurator.step = 2;

                            let url = this.submitUrl + '/' + this.configurator.id;

                            API.PUT(url, this.configurator).then(response => {
                                that.configurator = response.data;
                            });
                        }
                    },
                    convertedPrice: function (price) {
                        if (isNaN(price)) {
                            return '';
                        }

                        price = price.toFixed(2);
                        price = price.replace(".", ",");
                        price = "€ " + price;

                        return price;
                    },
                    stretchInput(element) {
                        let cs = getComputedStyle(element);
                        let offset = 0;

                        if (!element.value && element.placeholder) {
                            let empty = true;
                            element.value = element.placeholder;
                        }

                        element.style.width = "auto";

                        if (cs.boxSizing == "border-box") {
                            offset = element.offsetWidth;
                        }
                        else if (cs.boxSizing == "padding-box") {
                            offset = element.clientWidth;
                        }

                        // Safari misreports scrollWidth, so we will instead set scrollLeft to a
                        // huge number, and read that back to see what it was clipped to
                        element.scrollLeft = 1e+10;

                        var width = Math.max(element.scrollLeft + offset, element.scrollWidth - element.clientWidth) + 13;

                        element.style.width = width + "px";
                    }
                },
                created: function () {
                },
                destroyed: function () {
                }
            })
        )
    }
});
