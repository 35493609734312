Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("order-blocked-message")) {
        Global.components.push(
            new Vue({
                el: '#order-blocked-message',
                store,
                data: function () {
                    return {
                    }
                },
                watch: {
                    showMessage(newValue, oldValue) {
                        if(newValue) {
                            setTimeout(this.hideMessage, 30000);
                        }
                    }
                },
                computed: {
                    showMessage: {
                        get: function () {
                            return this.$store.getters.showOrderBlockedMessage
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setShowOrderBlockedMessage', newValue)
                        }
                    }
                },
                methods: {
                    hideMessage() {
                        this.showMessage = false;
                    }
                }
            })
        )
    }
});
