Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("icon-title")) {
        Global.components.push(
            new Vue({
                el: '#icon-title',
                store,
                data: function () {
                    return {
                        title: document.getElementById("icon-title-field").value,
                        description: document.getElementById("icon-text-field") !== null ? document.getElementById("icon-text-field").value : null,
                    }
                },
                watch: {},
                computed: {
                    messageText: {
                        get: function () {
                            return this.$store.getters.messageText
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setMessageText', newValue)
                        }
                    },
                },
                mounted: function () {
                    if (this.description !== null) {
                        this.fitDescription();
                    }
                },
                methods: {
                    updateContent: function (e) {
                        const that = this;

                        if (e !== "blur") {
                            e.currentTarget.blur();
                        }

                        if (this.title || this.description) {

                            let formData = new FormData();
                            formData.append('_method', 'put');

                            if (this.title !== null) {
                                formData.append('title', this.title);
                            }

                            if (this.description !== null) {
                                formData.append('description', this.description);
                            }

                            API.POST(this.$el.dataset.updateUrl, formData).then(response => {
                                that.messageText = response.message;
                            });
                        }
                    },
                    fitDescription: function() {

                        let that = this;
                        setTimeout(function() {
                            that.$refs.description.style.height = 'auto';
                            that.$refs.description.style.height = that.$refs.description.scrollHeight + 'px';
                        }, 0);

                    }
                },
                created: function () {
                },
                destroyed: function () {
                }
            })
        )
    }
});
