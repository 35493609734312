Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("add-pointer-popup")) {
        Global.components.push(
            new Vue({
                el: '#add-pointer-popup',
                store,
                data: function () {
                    return {
                        searchResults: null,
                        searchQuery: '',
                        itemsUrl: document.getElementById("add-pointer-popup").dataset.itemsUrl,
                        updatePointerUrl: document.getElementById("add-pointer-popup").dataset.updatePointerUrl,
                        deletePointerUrl: document.getElementById("add-pointer-popup").dataset.deletePointerUrl,
                        addPointerUrl: document.getElementById("add-pointer-popup").dataset.addPointerUrl,
                        floorplansUrl: document.getElementById("add-pointer-popup").dataset.floorplansUrl,
                        pointerTitleField: this.openedPointer !== null && this.openedPointer !== undefined ? this.openedPointer.title : '',
                        selectedPointerItem: (this.openedPointer !== null && this.openedPointer !== undefined && this.newPointer === false) && this.openedPointer.items !== undefined ? this.openedPointer.items[0] : null,
                        keyCodeDigits: [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105],
                        keyCodeFractals: [188, 190, 100]
                    }
                },
                computed: {
                    floorplans: {
                        get: function () {
                            return this.$store.getters.projectFloorplans;
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setProjectFloorplans', newValue)
                        }
                    },
                    activeFloorplan: {
                        get: function () {
                            return this.$store.getters.activeFloorplan
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setActiveFloorplan', newValue);
                        }
                    },
                    showAddPointerPopup: {
                        get: function () {
                            return this.$store.getters.showAddPointerPopup
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setShowAddPointerPopup', newValue);
                        }
                    },
                    newPointer: {
                        get: function () {
                            return this.$store.getters.newPointer
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setNewPointer', newValue);
                        }
                    },
                    openedPointer: {
                        get: function () {
                            return this.$store.getters.openedPointer
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setOpenedPointer', newValue);
                        }
                    },
                    messageText: {
                        get: function () {
                            return this.$store.getters.messageText
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setMessageText', newValue)
                        }
                    },
                    mousePosition: {
                        get: function () {
                            return this.$store.getters.mousePosition
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setMousePosition', newValue);
                        }
                    }
                },
                watch: {
                    /*
                    * Als de zoekterm wijzigt, haal resultaten op
                    */
                    searchQuery: function (newValue) {
                        if (newValue.length > 1) {
                            this.getResults();
                        } else {
                            this.getAllResults();
                        }
                    },
                    /*
                    * Wanneer een nieuwe pointer wordt gevuld, wordt de pointer toegevoegd.
                    */
                    newPointer: function (newValue) {
                        if (newValue) {
                            this.addPointer();
                        }
                    },
                    /*
                    * Vul selectedPointer item obv de pointer waar op geklikt is.
                    */
                    openedPointer: function (newValue) {
                        if (newValue !== null) {
                            this.pointerTitleField = newValue.title;

                            if (newValue.items !== null && newValue.items !== undefined) {
                                this.selectedPointerItem = newValue.items[0];
                            }
                        }
                    },
                    /*
                    * Reset de data van de pointer popup wanneer een nieuwe pointer moet worden teegevoegd.
                    */
                    showAddPointerPopup: function (newValue) {
                        if (this.newPointer) {
                            this.selectedPointerItem = null;
                            this.pointerTitleField = '';
                        }
                    },
                },
                mounted: function () {
                    this.getAllResults();
                },
                methods: {
                    /*
                    * Haal alle items op uit de database, die toegevoegd kunnen worden een een pointer
                    */
                    getAllResults: function () {
                        const that = this;
                        let url = this.itemsUrl;

                        API.GET(url).then(response => {
                            that.searchResults = response;
                        });
                    },
                    /*
                    * Haal items op obv de zoekterm die ingevoerd is.
                    */
                    getResults: function () {
                        const that = this;
                        let url = Global.updateQueryStringParameter(this.itemsUrl, 'query', this.searchQuery);

                        API.GET(url).then(response => {
                            that.searchResults = response;
                        });
                    },
                    /*
                    * Haal plattegronden op
                    */
                    getFloorplans: function () {
                        const that = this;

                        API.GET(this.floorplansUrl).then(data => {
                            that.floorplans = data.data;

                            that.floorplans.forEach(item => {
                                if (this.activeFloorplan.id === item.id) {
                                    this.activeFloorplan.pointers = item.pointers;
                                }
                            });
                        });
                    },
                    /*
                    * Voeg een pointer toe aan de database.
                    */
                    addPointer: function () {
                        const that = this;

                        let url = this.addPointerUrl;

                        let formData = new FormData();
                        formData.append('source', 'floorplan');
                        formData.append('sourceID', this.activeFloorplan.id);
                        formData.append('left', this.mousePosition.x);
                        formData.append('top', this.mousePosition.y);

                        API.POST(url, formData).then(response => {
                            that.openedPointer = response.data;
                            that.newPointer = false;

                            that.getFloorplans();
                            that.getAllResults();
                        });
                    },
                    /*
                    * Wijzig een pointer in de database
                    */
                    updatePointer: function () {
                        const that = this;

                        this.$validator.validateAll().then(result => {
                            if (result) {
                                if (this.selectedPointer !== null) {
                                    let url = that.updatePointerUrl;
                                    let id = that.openedPointer.pointerID !== null && that.openedPointer.pointerID !== undefined ? that.openedPointer.pointerID : that.openedPointer.id;

                                    let formData = new FormData();
                                    formData.append('pointerID', id);
                                    formData.append('link', that.selectedPointerItem.link);
                                    formData.append('source', that.selectedPointerItem.source);
                                    formData.append('sourceID', that.selectedPointerItem.sourceID);
                                    formData.append('amount', that.selectedPointerItem.amount || 1);
                                    formData.append('price', that.selectedPointerItem.price);
                                    formData.append('title', that.pointerTitleField);

                                    API.POST(url, formData).then(response => {
                                        that.getFloorplans();
                                        that.messageText = response.message;
                                        that.openedPointer = null;
                                        that.showAddPointerPopup = false;
                                    });
                                }
                            }
                        });
                    },
                    /*
                    * Verwijder een pointer uit de database
                    */
                    deletePointer: function () {
                        const that = this;
                        let formData = new FormData();

                        let id;

                        if (this.openedPointer) {
                            if (this.openedPointer.pointerID !== null && this.openedPointer.pointerID !== undefined) {
                                id = this.openedPointer.pointerID;
                            } else {
                                if (this.openedPointer.id) {
                                    id = this.openedPointer.id;
                                }
                            }
                        }

                        formData.append('pointerID', id);
                        formData.append('_method', 'delete');

                        let url = this.deletePointerUrl;

                        API.POST(url, formData).then(response => {
                            that.getFloorplans();
                            that.openedPointer = null;
                            that.showAddPointerPopup = false;
                        });
                    },
                    /*
                    * Sluit de popup, en verwijder de pointer als er geen product aan gekoppeld is.
                    */
                    hidePopup() {
                        if (this.openedPointer && (! this.openedPointer.items || (this.openedPointer.items && this.openedPointer.items.length === 0))) {
                            this.deletePointer();
                        }

                        this.showAddPointerPopup = false;
                        this.newPointer = false;
                        this.openedPointer = null;
                        this.pointerTitleField = '';
                    },

                    onSelectPointerItemClick(item) {

                        let amount = 1;
                        if(this.selectedPointerItem !== null && this.selectedPointerItem.hasOwnProperty('amount')) {
                            amount = this.selectedPointerItem.amount;
                        }

                        this.selectedPointerItem = item;
                        this.selectedPointerItem.amount = amount;
                    },

                    onSelectPointerAmountKeyDown: function (selectedPointerItem, event) {
                        let inputElement = event.target;
                        let keyCode = event.keyCode;
                        let inputValue = inputElement.value;
                        let inputValueLength = inputValue.length;
                        let inputValueContainsFractal = (inputValueLength > 0 && (inputValue.indexOf('.') >= 0 || inputValue.indexOf(',') >= 0));

                        let isDigit = (this.keyCodeDigits.indexOf(keyCode) >= 0);
                        let isFractal = (this.keyCodeFractals.indexOf(keyCode) >= 0);

                        let isFractalAllowed = (selectedPointerItem.divisible || false);
                        if(isFractalAllowed && (inputValueLength == 0 || inputValueContainsFractal)) {
                            isFractalAllowed = false;
                        }


                        if(this.isShiftKey(event)){
                            event.preventDefault();
                        } else if(!this.isBackspaceKey(event) && !this.isPasteEvent(event)) {

                            if(isFractal && !isFractalAllowed) {
                                event.preventDefault();
                            } else if(!isFractal && !isDigit) {
                                event.preventDefault();
                            }
                        }
                    },
                    onSelectPointerAmountKeyUp: function (selectedPointerItem, event) {
                        let isFractalAllowed = (selectedPointerItem.divisible || false);

                        if(!isFractalAllowed) {
                            selectedPointerItem.amount = String(selectedPointerItem.amount).replace(/[^0-9]+/g, "");
                        } else {
                            selectedPointerItem.amount = String(selectedPointerItem.amount).replace(/,/g, '.');
                            selectedPointerItem.amount = selectedPointerItem.amount.replace(/[^0-9\.]+/g, "");
                        }
                    },
                    onSelectPointerPriceKeyDown: function (selectedPointerItem, event) {
                        let inputElement = event.target;
                        let keyCode = event.keyCode;
                        let inputValue = inputElement.value;
                        let inputValueLength = inputValue.length;
                        let inputValueContainsFractal = (inputValueLength > 0 && (inputValue.indexOf('.') >= 0 || inputValue.indexOf(',') >= 0));

                        let isDigit = (this.keyCodeDigits.indexOf(keyCode) >= 0);
                        let isFractal = (this.keyCodeFractals.indexOf(keyCode) >= 0);

                        let isFractalAllowed = true;

                        if(this.isShiftKey(event)){
                            event.preventDefault();
                        } else if(!this.isBackspaceKey(event) && !this.isPasteEvent(event)) {

                            if(isFractal && !isFractalAllowed) {
                                event.preventDefault();
                            } else if(!isFractal && !isDigit) {
                                event.preventDefault();
                            }
                        }
                    },
                    onSelectPointerPriceKeyUp: function (selectedPointerItem, event) {
                        let isFractalAllowed = true;

                        if(!isFractalAllowed) {
                            selectedPointerItem.amount = String(selectedPointerItem.amount).replace(/[^0-9]+/g, "");
                        } else {
                            selectedPointerItem.amount = String(selectedPointerItem.amount).replace(/,/g, '.');
                            selectedPointerItem.amount = selectedPointerItem.amount.replace(/[^0-9\.]+/g, "");
                        }
                    },

                    isPasteEvent: function(event) {
                        return ((event.ctrlKey || event.metaKey) && event.keyCode == 86);
                    },
                    isShiftKey: function(event){
                        return (event.shiftKey)
                    },
                    isBackspaceKey: function(event) {
                        return (event.keyCode == 8);
                    },
                    isArrowKey: function(event) {
                        return (event.keyCode == 37 || event.keyCode == 39);
                    },
                    isTabKey: function (event) {
                        return (event.keyCode == 9 || event.keyCode == 9 && event.shiftKey)
                    }

                },
                created: function () {
                },
                destroyed: function () {
                }
            })
        )
    }
});

