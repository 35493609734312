Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("product-config-summary")) {
        Global.components.push(
            new Vue({
                el: document.getElementById("product-config-summary"),
                store,
                data: function () {
                    return {
                        submitUrl: window.location.origin + '/dashboard/configurator',
                        currencyFormatter: new Intl.NumberFormat('nl-NL', {
                            style: 'currency',
                            currency: 'EUR'
                        }),
                        configuratorNextStepAllowed: false,
                        isAccountManager: document.getElementById("product-config-summary").dataset.isAccountManager,
                    }
                },
                computed: {
                    configurator: {
                        get() {
                            return this.$store.state.configurator;
                        },
                        set: function (value) {
                            this.$store.dispatch('setConfigurator', value)
                        }
                    },
                    configuratorStep2SubmitFormStepAllowed: {
                        get() {
                            return this.$store.state.configuratorStep2SubmitFormStepAllowed;
                        },
                        set: function (value) {
                            this.$store.dispatch('setConfiguratorStep2SubmitFormStepAllowed', value)
                        }
                    },
                    configuratorExternalSubmit: {
                        get() {
                            return this.$store.state.configuratorExternalSubmit;
                        },
                        set: function (value) {
                            this.$store.dispatch('setConfiguratorExternalSubmit', value)
                        }
                    },
                    totalPrice() {
                        let total = [];

                        this.configurator.contents.forEach(elem => {
                            total.push(elem.price * elem.quantity);
                        });

                        return total.reduce(function (total, num) {
                            return total + num
                        }, 0);
                    },
                    configuratorProductRemoved: {
                        get() {
                            return this.$store.state.configuratorProductRemoved;
                        },
                        set: function (value) {
                            this.$store.dispatch('setConfiguratorProductRemoved', value)
                        }
                    },
                    messageText: {
                        get: function () {
                            return this.$store.getters.messageText
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setMessageText', newValue)
                        }
                    },
                    submitStepAllowed() {
                        return this.configurator.step === 4 &&
                            (
                                this.isAccountManager && this.configurator.places !== undefined && this.configurator.places.available < this.configurator.places.total
                                ||
                                this.configurator.places !== undefined && this.configurator.places.available === 0
                            );
                    },
                    showSummary() {
                        return (this.configurator.step > 1);
                    }
                },
                mounted: function () {
                },
                methods: {
                    removeProduct(product, index) {
                        product.type === 'container' ? this.removeContainer() : this.removePlant(product, index);
                    },
                    removeContainer() {
                        const that = this;

                        this.configurator.container.id = 0;
                        this.configurator.contents = [];
                        this.configurator.step = 2;

                        API.POST(this.submitUrl, this.configurator).then(response => {
                            that.configurator = response.data;
                            that.configuratorProductRemoved = true;
                        });
                    },
                    removePlant(product, index) {
                        this.$delete(this.configurator.contents, index);
                        const that = this;

                        API.PUT(this.submitUrl + '/' + this.configurator.id, this.configurator).then(response => {
                            that.configurator = response.data;
                            that.configuratorProductRemoved = true;
                        });
                    },
                    productIsEditable(product) {
                        if (product.type === 'container') {
                            return this.configurator.step === 2 && this.configurator.contents.length < 2;
                        } else if (product.type === 'product') {
                            return this.configurator.step === 3;
                        }
                    },
                    updateConfigurator() {
                        const that = this;
                        let url = this.submitUrl + '/' + this.configurator.id;

                        API.PUT(url, this.configurator).then(response => {
                            that.configurator = response.data;
                        });
                    },
                    submitStep() {
                        const that = this;

                        let formData = new FormData();
                        formData.append('configuration', this.configurator.id);

                        API.POST(window.location.origin + '/dashboard/creations', formData).then(response => {
                            that.messageText = response.message;
                            Barba.Pjax.goTo(response.target);
                        });
                    },
                    nextStepAllowed() {
                        let nextStepAllowed = false;
                        if(this.configurator.step == 2 && typeof(this.configurator.container.type) != 'undefined') {
                            if(this.configurator.container.type == 'product' && this.configurator.container.id && typeof(this.configurator.container.dimensions) != 'undefined') {
                                nextStepAllowed = true;
                            } else if(this.configurator.container.type != 'product') {
                                nextStepAllowed = this.configuratorStep2SubmitFormStepAllowed;
                            }
                        }

                        return nextStepAllowed;
                    },
                    gotoNextStep() {
                        this.configuratorExternalSubmit = null;
                        if(this.nextStepAllowed()) {
                            this.configuratorExternalSubmit = this.configurator.step;
                        }
                    },
                    onScroll() {
                        let scrollTop = window.scrollY;
                        let sidebar = this.$el;
                        if(sidebar && sidebar.getBoundingClientRect) {
                            let parent = document.getElementById('product-config-container');
                            let parentCells = parent.querySelectorAll('.grid-x > .cell')

                            let sidebarPosition = sidebar.getBoundingClientRect();
                            let parentPosition = parent.getBoundingClientRect();

                            let marginTop = 0;

                            if(parentCells[0].getBoundingClientRect().width > parentCells[1].getBoundingClientRect().width)  {
                                let parentTop = (parentPosition.top + scrollTop)

                                if (scrollTop > parentTop) {
                                    marginTop = scrollTop - parentTop;
                                }

                                let maxMarginTop = parentPosition.height - sidebarPosition.height;
                                if (marginTop > maxMarginTop) {
                                    marginTop = maxMarginTop;
                                }

                                if(marginTop < 0) {
                                    marginTop = 0;
                                }
                            }

                            sidebar.style.marginTop = marginTop + 'px';
                        }
                    }
                },
                mounted: function () {
                    // window.addEventListener('scroll', this.onScroll);
                    // window.addEventListener('resize', this.onScroll);
                },
                destroyed: function () {
                    // window.removeEventListener('scroll', this.onScroll);
                    // window.removeEventListener('resize', this.onScroll);
                }
            })
        )
    }
});
