Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("floorplan-ui")) {
        Global.components.push(
            new Vue({
                el: '#floorplan-ui',
                store,
                mixins: [manageFloorplans],
                data: function () {
                    return {
                        useDefaultImageBusy: false
                    }
                },
                computed: {
                },
                watch: {
                },
                methods: {
                    /*
                    * Gebruik de default image
                    * */
                    useDefaultImage() {
                        this.useDefaultImageBusy = true;
                        API.POST(this.confirmUploadFloorplansUrl, {'_method': 'put', 'useDefaultFloorplan': true}).then(response => {
                            this.messageText = response.message;
                            this.getFloorplans();
                            setTimeout(function() {
                                console.log(this);
                                this.useDefaultImageBusy = false;
                            }.bind(this), 5000);
                        }).catch(error => {
                            this.useDefaultImageBusy = false;
                        });
                    }
                },
                created: function () {
                },
                destroyed: function () {
                }
            })
        )
    }
});
