Vue.component('info-icon', {
    props: ['content', 'class-prefix'],
    data: function () {
        return {
            contentFirstStage: false,
            contentSecondStage: false,
            contentPosition: 'bottom-right'
        }
    },
    methods: {
        prefixClass(value) {

            let prefix = (this.classPrefix != null && this.classPrefix != '' ? this.classPrefix + '__' : '');
            if(prefix == '') {
                return value;
            }

            if(typeof(value) == 'string') {
                return prefix + value;
            } else if(typeof(value) == 'object') {
                let classNames = {};
                for(var a in value) {
                    classNames[prefix + a] = value[a];
                }
                return classNames;
            }

        },
        className(name) {
            if(this.classPrefix != null && this.classPrefix != '') {
                return this.classPrefix + '__' + name;
            }

            return name;
        },
        onWindowResize() {
            this.positionContent();
        },
        onElementMouseEnter() {
            this.showContent();
        },
        onElementMouseLeave() {
            this.hideContent();
        },
        showContent() {
            // first goto display block and opacity 0
            this.contentFirstStage = true;

            setTimeout(function() {
                // then position
                this.positionContent();
            }.bind(this), 0);

            setTimeout(function() {
                // then show it to the user
                this.contentSecondStage = true;
            }.bind(this), 0);

        },
        hideContent() {
            // animate out of sight
            this.contentSecondStage = false;

            // then remove from viewport
            this.contentFirstStage = false;
        },
        positionContent() {
            if(this.contentFirstStage) {
                // get the elements
                let iconElement = this.$refs.icon;
                let contentElement = this.$refs.content;

                // get element dimensions and offset
                let iconBounds = iconElement.getBoundingClientRect();
                let contentBounds = contentElement.getBoundingClientRect();

                // determine space between icon and right side
                let spaceBetweenRight = window.innerWidth - (iconBounds.left + contentBounds.width);

                // determine space between icon and bottom side
                let spaceBetweenBottom = window.innerHeight - ((iconBounds.top + iconBounds.height) + contentBounds.height);

                // determine position
                this.contentPosition = (spaceBetweenBottom < 5 ? 'top' : 'bottom') + '-' + (spaceBetweenRight < 5 ? 'left' : 'right');
            }
        }
    },
    mounted() {
        this.$el.addEventListener('mouseenter', this.onElementMouseEnter);
        this.$el.addEventListener('mouseleave', this.onElementMouseLeave);

        window.addEventListener('resize', this.onWindowResize);
        window.addEventListener('scroll', this.onWindowResize);
    },
    destroyed() {
        window.removeEventListener('resize', this.onWindowResize);
        window.removeEventListener('scroll', this.onWindowResize);
    },
    template:
        '<div :class="prefixClass(\'info-wrapper\')">\n' +
        '   <div :class="prefixClass(\'info-icon\')" ref="icon">?</div>\n' +
        '   <div :class="prefixClass({' +
            '\'info-content\': true, ' +
            '\'info-content--first\': contentFirstStage, ' +
            '\'info-content--second\': contentSecondStage, ' +
            '\'info-content--top-left\' : contentPosition == \'top-left\', ' +
            '\'info-content--top-right\' : contentPosition == \'top-right\', ' +
            '\'info-content--bottom-left\' : contentPosition == \'bottom-left\', ' +
            '\'info-content--bottom-right\' : contentPosition == \'bottom-right\'' +
        '})" v-html="content" ref="content"></div>\n' +
        '</div>'
});
