let API = {
    NAMESPACES: {},
    DEBOUNCE: function (namespace) {
        // Cancel current item in namespace
        if(API.NAMESPACES[namespace] !== null && typeof API.NAMESPACES[namespace] == "object" && typeof API.NAMESPACES[namespace].cancel == "function") {
            API.NAMESPACES[namespace].cancel();
        }

        // Return cancel API
        return APICancelable.create(namespace);
    },
    GET: function (url) {
        let that = this;

        return axios({
            method: 'GET',
            url: url,
            cancelToken: typeof this.cancelToken != 'undefined' ? this.cancelToken : null,
        }).then(function (response) {
            return response.data;
        }).catch(function (error) {
            store.commit('setErrorObject', error.data);
        }).then(function(response) {
            // Call done for cancelable
            if(typeof that.done == 'function')
                that.done();

            return response;
        });
    },
    POST: function (url, data) {
        let that = this;

        return axios({
            method: 'POST',
            url: url,
            data: data,
            cancelToken: typeof this.cancelToken != 'undefined' ? this.cancelToken : null,
        }).then(function (response) {
            return response.data;
        }).catch(function (error) {
            store.commit('setErrorObject', error.data);
        }).then(function(response) {
            // Call done for cancelable
            if(typeof that.done == 'function')
                that.done();

            return response;
        });
    },
    PUT: function (url, data) {
        let that = this;

        return axios({
            method: 'PUT',
            url: url,
            data: data,
            cancelToken: typeof this.cancelToken != 'undefined' ? this.cancelToken : null,
        }).then(function (response) {
            return response.data;
        }).catch(function (error) {
            store.commit('setErrorObject', error.data);
        }).then(function(response) {
            // Call done for cancelable
            if(typeof that.done == 'function')
                that.done();

            return response;
        });
    },
    DELETE: function (url, data) {
        let that = this;

        return axios({
            method: 'DELETE',
            url: url,
            data: data,
            cancelToken: typeof this.cancelToken != 'undefined' ? this.cancelToken : null,
        }).then(function (response) {
            return response.data;
        }).catch(function (error) {
            store.commit('setErrorObject', error.data);
        }).then(function(response) {
            // Call done for cancelable
            if(typeof that.done == 'function')
                that.done();

            return response;
        });
    },
};
