Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("product-config-step-3")) {
        Global.components.push(
            new Vue({
                el: document.getElementById("product-config-step-3"),
                store,
                data: function () {
                    return {
                        submitUrl: window.location.origin + '/dashboard/configurator',
                        loadPreset: true,
                        filters: [],
                        filterValues: [],
                        optionMapping: {
                            type: {
                                id: 7,
                                values: {
                                    outdoor: 21,
                                    indoor: 24,
                                    artificial: 32
                                }
                            },
                            substrate: {
                                id: 13,
                                values: {
                                    hydro: 89,
                                    soil: 88
                                }
                            },
                        },
                        rawResponse: null,
                        submitFormStepAllowed: false,
                    }
                },
                computed: {
                    configurator: {
                        get() {
                            return this.$store.state.configurator;
                        },
                        set: function (value) {
                            this.$store.dispatch('setConfigurator', value)
                        }
                    },
                    filtersUrl() {
                        let url = window.location.origin + '/dashboard/configurator/' + this.configurator.id + '/filters';
                        url = Global.updateQueryStringParameter(url, 'type', 'preset');

                        return url;
                    },
                    messageText: {
                        get: function () {
                            return this.$store.getters.messageText
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setMessageText', newValue)
                        }
                    },
                },
                watch: {
                    filterValues(newValue, oldValue) {
                        // Disable any re-loading of the preset from the configurator object
                        this.loadPreset = false;

                        // When type choice is "Outdoor" we pre-select the "Soil" substrate
                        if(this.filterValues.hasOwnProperty(this.optionMapping.type.id)
                            && this.filterValues[this.optionMapping.type.id] == this.optionMapping.type.values.outdoor) {

                            this.filterValues[this.optionMapping.substrate.id] = this.optionMapping.substrate.values.soil;
                        }

                        // When type choice is "Kunstbeplanting" we remove any substrate choice
                        if(this.filterValues.hasOwnProperty(this.optionMapping.type.id)
                            && this.filterValues[this.optionMapping.type.id] == this.optionMapping.type.values.artificial) {

                            delete(this.filterValues[this.optionMapping.substrate.id]);
                        }

                        // Save the preset into the configurator object
                        this.savePresetIntoConfigurator();

                        this.checkIfSubmitFormStepAllowed();
                    },
                    configurator(newValue, oldValue) {
                        if(this.loadPreset && this.loadPresetFromConfigurator()) {
                            this.loadPreset = false;
                            this.checkIfSubmitFormStepAllowed();
                        }
                    }
                },
                mounted: function () {
                    this.getFilters();
                },
                methods: {
                    checkIfSubmitFormStepAllowed() {
                        let isAllowed = true;

                        if(!this.filterValues.hasOwnProperty(this.optionMapping.type.id)) {
                            isAllowed = false;
                        }

                        if ((this.filterValues.hasOwnProperty(this.optionMapping.type.id)
                            && (this.filterValues[this.optionMapping.type.id] == this.optionMapping.type.values.indoor || this.filterValues[this.optionMapping.type.id] == this.optionMapping.type.values.outdoor)) && !this.filterValues.hasOwnProperty(this.optionMapping.substrate.id)) {
                            isAllowed = false;
                        }

                        this.submitFormStepAllowed = isAllowed;

                        return isAllowed;
                    },
                    gotoStep3() {
                        const that = this;

                        if (this.configurator.step > 3) {
                            this.configurator.contents = this.configurator.contents.filter(elem => {
                                return elem.type === 'container'
                            });

                            this.configurator.step = 3;

                            let url = this.submitUrl + '/' + this.configurator.id;

                            API.PUT(url, this.configurator).then(response => {
                                that.configurator = response.data;
                            });
                        }
                    },
                    loadPresetFromConfigurator() {
                        if(typeof(this.configurator) != 'undefined' && this.configurator != null && this.configurator.hasOwnProperty('preset') && this.configurator.preset.hasOwnProperty('filter')) {

                            this.configurator.preset.filter.forEach((item) => {
                                this.filterValues[item.filter] = item.value;
                            });

                            return true;
                        }
                        return false;
                    },
                    savePresetIntoConfigurator() {
                        this.configurator.preset = {
                            options: {},
                            filter: []
                        };

                        for(var filterId in this.filterValues) {
                            if(this.filterValues[filterId] != null) {
                                this.configurator.preset.filter.push({
                                    filter: filterId,
                                    value: this.filterValues[filterId]
                                });
                            }
                        }

                        for(let mappingKey in this.optionMapping) {
                            this.configurator.preset.options[mappingKey] = null;
                            for(let valueKey in this.optionMapping[mappingKey].values) {
                                if(this.filterValues.hasOwnProperty(this.optionMapping[mappingKey].id)
                                    && this.filterValues[this.optionMapping[mappingKey].id] == this.optionMapping[mappingKey].values[valueKey]) {

                                    this.configurator.preset.options[mappingKey] = valueKey;
                                }
                            }
                        }
                    },
                    submitStep() {
                        const that = this;
                        let data = JSON.parse(JSON.stringify(this.configurator));
                        ++data.step;

                        this.savePresetIntoConfigurator();

                        API.PUT(this.submitUrl + '/' + this.configurator.id, data).then(response => {
                            that.configurator = response.data;
                        });
                    },
                    getFilters() {
                        const that = this;

                        API.GET(this.filtersUrl).then(response => {
                            this.filters = response.data;
                            that.$nextTick(() => {
                                this.initializeFilters();
                            });
                        });
                    },
                    initializeFilters() {
                        // Does nothing for now
                    },
                    isFilterShown(filter) {

                        if(filter.id == this.optionMapping.substrate.id) {
                            // Substrate choice is only shown when type is "Indoor" and Outdoor
                            return (this.filterValues.hasOwnProperty(this.optionMapping.type.id)
                                && (this.filterValues[this.optionMapping.type.id] == this.optionMapping.type.values.indoor || this.filterValues[this.optionMapping.type.id] == this.optionMapping.type.values.outdoor));
                        }
                        return true;
                    },
                    isFilterReadonly(filter) {
                        if(filter.id == this.optionMapping.substrate.id) {
                            // Substrate choice is only needed when type is "Indoor", with "Outdoor" we pre select "Soil" and gray out the choice
                            return (this.filterValues.hasOwnProperty(this.optionMapping.type.id)
                                && this.filterValues[this.optionMapping.type.id] == this.optionMapping.type.values.outdoor);
                        }
                        return false;
                    },
                },
                created: function () {
                },
                destroyed: function () {
                }
            })
        )
    }
});
