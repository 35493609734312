Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("board-filters")) {
        Global.components.push(
                new Vue({
                el: '#board-filters',
                store,
                data: function () {
                    return {
                    }
                },
                computed: {
                    activeBoardFilter: {
                        get: function () {
                            return this.$store.getters.activeBoardFilter
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setActiveBoardFilter', newValue);
                            Global.BlazyInstance.load(document.querySelectorAll('.board-card__bg'));
                        }
                    }
                },
                mounted: function () {
                },
                methods: {
                },
                created: function () {
                },
                destroyed: function () {
                }
            })
        )
    }
});
