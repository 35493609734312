Barba.Dispatcher.on("transitionCompleted", function () {
    if (!document.getElementById("shoppingcart")) {
        return;
    }
    Global.components.push(
        new Vue({
            el: '#shoppingcart',
            store,
            data: function () {
                return {
                    checkOutUrl: document.getElementById('shoppingcart').dataset.checkoutUrl,
                    updateProductUrl: document.getElementById('shoppingcart').dataset.updateProductUrl,
                    products: null,
                    prices: null,
                    options: null,
                    agreements: null,
                    count: null,
                    updating: false,
                    fetched: false,
                    keyCodeDigits: [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105],
                    keyCodeFractals: [188, 190, 100]
                }
            },
            computed: {
                messageText: {
                    get: function () {
                        return this.$store.getters.messageText
                    },
                    set: function (newValue) {
                        this.$store.dispatch('setMessageText', newValue)
                    }
                },
                showMessage: {
                    get: function () {
                        return this.$store.getters.showMessage
                    },
                    set: function (newValue) {
                        this.$store.dispatch('setShowMessage', newValue)
                    }
                },
                hasOnlyQuotation() {
                    return (this.count !== null && this.count.buyable == 0);
                }
            },
            watch: {},
            mounted: function () {
                this.getShoppingcartDetails();
            },
            methods: {
                handleResponse: function(scope, response) {
                    scope.products = response.data.lines.products;
                    scope.prices = response.data.lines.prices;
                    scope.options = response.data.lines.options;
                    scope.agreements = response.data.agreements;
                    scope.count = response.data.count;
                },
                convertedPrice: function (price) {
                    if (isNaN(price)) {
                        return '';
                    }
                    else if (price === 0) {
                        return '-';
                    }

                    price = price.toFixed(2);
                    price = price.replace(".", ",");
                    price = "€ " + price;

                    return price;
                },
                getShoppingcartDetails: function () {
                    const that = this;

                    API.GET(this.checkOutUrl).then(response => {
                        that.fetched = true;

                        that.handleResponse(that, response);
                    });
                },
                onProductQuantityKeyDown: function (product, event) {
                    let inputElement = event.target;
                    let keyCode = event.keyCode;
                    let inputValue = inputElement.value;
                    let inputValueLength = inputValue.length;
                    let inputValueContainsFractal = (inputValueLength > 0 && (inputValue.indexOf('.') >= 0 || inputValue.indexOf(',') >= 0));

                    let isDigit = (this.keyCodeDigits.indexOf(keyCode) >= 0);
                    let isFractal = (this.keyCodeFractals.indexOf(keyCode) >= 0);

                    let isFractalAllowed = (product.options.divisible || false);
                    if(isFractalAllowed && (inputValueLength == 0 || inputValueContainsFractal)) {
                        isFractalAllowed = false;
                    }


                    if(this.isShiftKey(event)){
                        event.preventDefault();
                    } else if(!this.isBackspaceKey(event) && !this.isPasteEvent(event)) {

                        if(isFractal && !isFractalAllowed) {
                            event.preventDefault();
                        } else if(!isFractal && !isDigit) {
                            event.preventDefault();
                        }
                    }
                },
                onProductQuantityKeyUp: function (product, event) {
                    const that = this;

                    let isFractalAllowed = (product.options.divisible || false);

                    if(!isFractalAllowed) {
                        product.qty = product.qty.replace(/[^0-9]+/g, "");
                    } else {
                        product.qty = product.qty.replace(/,/g, '.');
                        product.qty = product.qty.replace(/[^0-9\.]+/g, "");
                    }
                },
                updateProductQuantity: function (product) {
                    const that = this;

                    let isFractalAllowed = (product.options.divisible || false);

                    if(!isFractalAllowed) {
                        product.qty = product.qty.replace(/[^0-9]+/g, "");
                    } else {
                        product.qty = product.qty.replace(/,/g, '.');
                        product.qty = product.qty.replace(/[^0-9\.]+/g, "");
                    }

                    if (product.qty > 100) {
                        this.messageText = "U kunt niet meer dan 100 dezelfde producten bestellen";
                        product.qty = 100;
                        return;
                    }

                    if(!isFractalAllowed && product.qty < 1) {
                        product.qty = 1;
                    } else if(isFractalAllowed && product.qty < 0) {
                        product.qty = 1;
                    }

                    let url = that.updateProductUrl.replace('::ROWID::', product.rowId);

                    let formData = new FormData();
                    formData.append('_method', 'put');
                    formData.append('quantity', product.qty);

                    API.POST(url, formData).then(response => {
                        that.updating = false;

                        that.handleResponse(that, response);
                    });
                },
                deleteProduct: function (rowId) {
                    if (confirm('Weet het zeker dat u het product wilt verwijderen uit de winkelwagen?') !== true) {
                        return false;
                    }

                    if (!this.updating) {
                        this.updating = true;
                        const that = this;

                        let url = this.updateProductUrl.replace('::ROWID::', rowId);

                        let formData = new FormData();
                        formData.append('_method', 'delete');

                        API.POST(url, formData).then(response => {

                            if (!response.data.lines || !response.data.lines.products) {
                                location.reload();
                            }

                            that.updating = false;

                            that.messageText = response.message;

                            that.handleResponse(that, response);
                        });
                    }
                },
                updateAgreement: function (agreement) {
                    const that = this;
                    if (!this.updating) {
                        this.updating = true;

                        let formData = new FormData();
                        formData.append('agreement', agreement.id);

                        API.POST(that.checkOutUrl, formData).then(response => {
                            that.handleResponse(that, response);
                            that.updating = false;
                        });
                    }
                },
                agreementCosts: function (agreement) {
                    let out = [];

                    if(agreement.hasOwnProperty('buy') && agreement.buy != false && agreement.buy != null) {
                        out.push(this.convertedPrice(agreement.buy.price));

                        if(agreement.hasOwnProperty('maintenance') && agreement.maintenance != false && agreement.maintenance != null) {
                            out.push(this.convertedPrice(agreement.maintenance.price) + ' ' + agreement.maintenance.condition);
                        }

                    } else if(agreement.hasOwnProperty('lease') && agreement.lease != false && agreement.lease != null) {
                        out.push(this.convertedPrice(agreement.lease.price) + ' ' + agreement.lease.condition);
                    }

                    return out.join(' + ');
                },
                isPasteEvent: function(event) {
                    return ((event.ctrlKey || event.metaKey) && event.keyCode == 86);
                },
                isShiftKey: function(event){
                    return (event.shiftKey)
                },
                isBackspaceKey: function(event) {
                    return (event.keyCode == 8);
                },
                isArrowKey: function(event) {
                    return (event.keyCode == 37 || event.keyCode == 39);
                },
                isTabKey: function (event) {
                    return (event.keyCode == 9 || event.keyCode == 9 && event.shiftKey)
                }
            },
            created: function () {
            },
            destroyed: function () {
            }
        })
    )
});

