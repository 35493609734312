Vue.component('file', {
    props: ['name', 'text', 'extensions', 'filesize', 'buttonText', 'extensions-error-message', 'filesize-error-message'],
    data: function () {
        return {
            selectedFile: null,
            isDragging: false,
            isHovered: false,
            top: 0,
            left: 0,
            hasError: false,
            errorMessage: null
        }
    },
    methods: {
        dragover: function(e) {
            this.isDragging = true;
            this.moveInput(e, 'element');
        },
        dragleave: function(e)
        {
            if(!this.$el.contains(e.relatedTarget) && e.relatedTarget.tagName != 'INPUT') {
                this.isDragging = false;
                this.resetInput();
            }
        },
        drop: function(e)
        {
            this.isDragging = false;
            this.resetInput();
        },
        mousemove: function(e)
        {
            if(!this.isDragging) {
                this.isHovered = true;
                this.moveInput(e, 'click');
            }
        },
        mouseleave: function(e)
        {
            if(!this.isDragging) {
                this.isHovered = false;
                this.resetInput();
            }
        },
        moveInput: function(e, boundary) {
            let mouse = Global.mousePositionElement(e, this.$refs.click);

            let element = this.$el.getBoundingClientRect();
            let click = this.$refs.click.getBoundingClientRect();
            let input = this.$refs.input.getBoundingClientRect();

            if(boundary == 'element') {
                boundary = element;
            } else if(boundary == 'click') {
                boundary = click;
            }

            let top = mouse.y - (input.height / 2);
            let topMin = (boundary.top - click.top);
            let topMax = ((boundary.height - (click.top - boundary.top)) - input.height);

            if(top < topMin) {
                top = topMin;
            }
            if(top > topMax) {
                top = topMax;
            }
            let left = mouse.x - (input.width / 2);
            let leftMin = (boundary.left - click.left);
            let leftMax = ((boundary.width - (click.left - boundary.left)) - input.width);
            if(left < leftMin) {
                left = leftMin;
            }
            if(left > leftMax) {
                left = leftMax;
            }

            this.top = top;
            this.left = left;
        },
        resetInput: function() {
            this.top = 0;
            this.left = 0;
        },
        selectFile: function(e) {
            // Reset the error
            this.hasError = false;
            this.errorMessage = null;

            // Analyze the files
            let files = this.$refs.input.files;
            if(files.length > 0) {

                // Check restrictions
                if(!this.hasError && typeof(this.extensions) != 'undefined' && this.extensions != null) {
                    let allowedExtenstions = this.extensions.split(',');
                    let fileExtension = files.item(0).name.split('.').pop();
                    if(allowedExtenstions.indexOf(fileExtension) < 0) {
                        this.hasError = true;
                        this.errorMessage = this.extensionsErrorMessage || 'Extension not allowed';
                    }
                }
                if(!this.hasError && typeof(this.filesize) != 'undefined' && this.filesize != null && this.filesize > 0) {
                    let allowedFilesize = this.filesize;
                    let fileSize = files.item(0).size;
                    if(fileSize > allowedFilesize) {
                        this.hasError = true;
                        this.errorMessage = this.filesizeErrorMessage || 'File too big';
                    }
                }
                if(this.hasError) {
                    this.$refs.input.value = '';
                } else {
                    this.selectedFile = files.item(0).name;
                }
            } else {
                this.selectedFile = null;
            }
        }
    },
    template: '<div class="file" ref="container" @dragover="dragover($event)" @dragleave="dragleave($event)" @drop="drop($event)" :class="{\'file--dragging\': isDragging}">\n' +
        '    <div class="file__label" ref="label">' +
        '       <template v-if="hasError"><span class="has-error">{{ errorMessage }}</span></template>\n' +
        '       <template v-if="!hasError && selectedFile === null">{{ text }}</template>\n' +
        '       <template v-if="!hasError && selectedFile !== null">{{ selectedFile }}</template>\n' +
        '        </div>\n' +
        '    <div class="file__click button" ref="click" @mousemove="mousemove($event)" @mouseleave="mouseleave($event)" :class="{\'file__click--hover\': isHovered}">' +
        '       {{ buttonText }}' +
        '       <input class="file__input" type="file" :name="name" ref="input" :style="{top: top + \'px\', left: left + \'px\'}" @change="selectFile($event)"/>\n' +
        '        </div>\n' +
        '</div>'
})