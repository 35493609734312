const Global = {
    hasStorage: function() {
        return typeof(window.sessionStorage) !== "undefined";
    },
    getStorage: function(key) {
        if (! this.hasStorage()) {
            return null;
        }

        let items = window.sessionStorage.getItem(key);
        if (items !== null) {
            items = JSON.parse(items);
        }

        if (items !== null && typeof(items) === 'object' && items.hasOwnProperty(window.location.pathname)) {
            return items[window.location.pathname];
        }

        return {};
    },

    setStorage: function(key, data) {
        if (! this.hasStorage() || data === null) {
            return data;
        }

        let items = window.sessionStorage.getItem(key);
        if (items !== null) {
            items = JSON.parse(items);
        }

        if (items === null || typeof(items) !== 'object') {
            items = {};
        }

        items[window.location.pathname] = data;
        window.sessionStorage.setItem(key, JSON.stringify(items));

        return items;
    },

    mergeStorage: function(key, data) {
        if (! this.hasStorage()) {
            return data;
        }

        let _data = this.getStorage(key);
        data = _data.concat(data);

        this.setStorage(key, data);

        return data;
    },

    Blazy: function () {
        Global.BlazyInstance = new Blazy({
            breakpoints: [{
                width: 0,
                src: 'data-src-small'
            }, {
                width: 640,
                src: 'data-src-medium'
            }, {
                width: 1024,
                src: 'data-src-large'
            }, {
                width: 1200,
                src: 'data-src-xlarge'
            }, {
                width: 1440,
                src: 'data-src-xxlarge'
            }]
        })
    },
    Barba: function () {
        Barba.Dispatcher.on('initStateChange', function () {
            Global.components.forEach(component => {
                component.$destroy();
            });
            Global.eventBus.$destroy();

            Barba.Dispatcher.off('initStateChange');
        });

        let FadeTransition = Barba.BaseTransition.extend({
            start: function () {
                // As soon the loading is finished and the old page is faded out, let's fade the new page
                Promise
                    .all([this.newContainerLoading, this.fadeOut()])
                    .then(this.fadeIn.bind(this));
            },

            fadeOut: function () {
                return new Promise((resolve, reject) => {
                    document.querySelector('.barba-bg').className += ' barba-bg--visible';

                    window.setTimeout(function () {
                        resolve();
                    }, 500);
                });
            },

            fadeIn: function () {
                let _this = this;
                (this.oldContainer).style.display = 'none';

                window.scrollTo(0, 0);

                document.querySelector('.barba-bg').classList.remove('barba-bg--visible');
                _this.done();
            }
        });

        Barba.Pjax.getTransition = function () {
            return FadeTransition;
        };
    },
    EventBus: function() {
      Global.eventBus = new Vue();
    },
    FormDictionary: {
        _default: (field) => `Dit veld is niet correct ingevuld.`,
        after: (field, [target, inclusion]) => `Dit veld moet groter ${inclusion ? 'of gelijk aan ' : ''} ${target}.`,
        alpha_dash: (field) => `Dit veld mag alleen alfanumerieke karakters, strepen en onderstrepingstekenen bevatten.`,
        alpha_num: (field) => `Dit veld mag alleen alfanumerieke karakters bevatten.`,
        alpha_spaces: (field) => `Dit veld mag alleen alfanumerieke karakters en spaties bevatten.`,
        alpha: (field) => `Dit veld mag alleen alfabetische karakters bevatten.`,
        before: (field, [target, inclusion]) => `De waarde van dit veld moet kleiner ${inclusion ? 'of gelijk aan' : ''} ${target} zijn.`,
        between: (field, [min, max]) => `De waarde van dit veld moet tussen ${min} en ${max} zijn.`,
        confirmed: (field) => `Dit bevestigingsveld komt niet overeen.`,
        credit_card: (field) => `Dit veld is ongeldig.`,
        date_between: (field, [min, max]) => `De datum moet tussen ${min} en ${max} zijn.`,
        date_format: (field, [format]) => `Dit veld moet het volgende formaat hebben: ${format}.`,
        decimal: (field, [decimals = '*'] = []) => `Dit veld mag alleen numerieke, en${!decimals || decimals === '*' ? ' ' : decimals}decimale nummers bevatten.`,
        digits: (field, [length]) => `Dit veld moet ${length} nummers bevatten.`,
        dimensions: (field, [width, height]) => `De dimensies voor dit veld moet ${width} pixels breed en ${height} pixels hoog zijn.`,
        email: (field) => `Dit veld moet een geldig e-mailadres bevatten.`,
        ext: (field) => `Dit veld moet een correct bestand bevatten.`,
        image: (field) => `Dit veld moet een afbeelding bevatten.`,
        included: (field) => `Dit veld moet een geldige waarde bevatten.`,
        integer: (field) => `Dit veld moet een nummer zijn.`,
        ip: (field) => `Dit veld moet een veilig ip adres zijn.`,
        length: (field, [length, max]) => {
            if (max) {
                return `Dit veld moet minimaal ${length} karakters en maximaal ${max} karakters bevatten.`;
            }

            return `Dit veld moet minimaal ${length} karakters lang zijn.`;
        },
        max: (field, [length]) => `Dit veld mag niet meer karakters bevatten dan ${length}.`,
        max_value: (field, [max]) => `Dit veld moet ${max} karakters of minder bevatten.`,
        mimes: (field) => `Dit veld moet Dit juiste type bestand bevatten.`,
        min: (field, [length]) => `Dit veld moet minimaal ${length} karakters zijn.`,
        min_value: (field, [min]) => `Dit veld moet minimaal ${min} karakters zijn.`,
        excluded: (field) => `Dit veld moet een geldige waarde bevatten`,
        numeric: (field) => `Dit veld mag alleen numerieke karakters bevatten.`,
        regex: (field) => `Dit veld is niet correct ingevoerd.`,
        required: (field) => `Dit veld is verplicht.`,
        size: (field, [size]) => `De bestandsgrootte van dit veld mag niet groter zijn dan ${formatFileSize(size)}.`,
        url: (field) => `Dit veld moet een valide URL zijn.`
    },
    Vvalidate: function () {
        Vue.use(VeeValidate, {
            locale: 'nl',
            events: 'blur|submit',
            classes: true,
            invalidateFalse: true,
            classNames: {
                invalid: 'input--invalid',
                valid: 'input--valid'
            },
            dictionary: {
                nl: {messages: Global.FormDictionary}
            }
        });
    },
    vDragged: function () {
        Vue.use(VDragged);
    },
    VueObserveVisibility: function () {
        Vue.use(VueObserveVisibility);
    },
    addClickOutsideDirective: function () {
        Vue.directive('click-outside', {

            bind: function (el, binding, vNode) {
                // Provided expression must evaluate to a function.
                if (typeof binding.value !== 'function') {
                    const compName = vNode.context.name
                    let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`
                    if (compName) {
                        warn += `Found in component '${compName}'`
                    }

                    console.warn(warn)
                }
                // Define Handler and cache it on the element
                const bubble = binding.modifiers.bubble
                const handler = (e) => {
                    if (bubble || (!el.contains(e.target) && el !== e.target)) {
                        binding.value(e)
                    }
                }
                el.__vueClickOutside__ = handler

                // add Event Listeners
                document.addEventListener('click', handler)
            },

            unbind: function (el, binding) {
                // Remove Event Listeners
                document.removeEventListener('click', el.__vueClickOutside__)
                el.__vueClickOutside__ = null
            }
        })
    },
    addDigitsDirective: function () {
        Vue.directive('digitsonly', (el, binding) => {
            if (/[\d\.]+/i.test(el.value)) {
            } else {
                let newValue = el.value.replace(/[a-zA-Z]+/ig, '');
                el.value = newValue;
                binding.value = el.value;
            }
        });
    },
    SubmitForm: function (form, gtmEvent) {
        form.classList.toggle('form--loading');

        if (form.querySelector('.form-message')) {
            form.querySelector('.form-message').remove();
        }

        let messageTemplate =
            '<div class="form-message form-message--active %status%">' +
            '   <div class="form-message__content">%message%</div>' +
            '   <span class="form-message__icon form-message__icon--success">' +
            '       <svg viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1671 566q0 40-28 68l-724 724-136 136q-28 28-68 28t-68-28l-136-136-362-362q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 295 656-657q28-28 68-28t68 28l136 136q28 28 28 68z"/></svg>' +
            '   </span>' +
            '   <span class="form-message__icon form-message__icon--error">' +
            '       <svg width="32" height="32" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1088 1248v224q0 26-19 45t-45 19h-256q-26 0-45-19t-19-45v-224q0-26 19-45t45-19h256q26 0 45 19t19 45zm30-1056l-28 768q-1 26-20.5 45t-45.5 19h-256q-26 0-45.5-19t-20.5-45l-28-768q-1-26 17.5-45t44.5-19h320q26 0 44.5 19t17.5 45z"/></svg>' +
            '   </span>' +
            '</div>';

        let formData = new FormData(form);

        return axios({
            method: 'POST',
            url: form.action,
            data: formData
        }).then(function (response) {
            if (response.data.target) {
                Barba.Pjax.goTo(response.data.target);
                return;
            }

            if (form.classList.contains('form--message')) {
                form.classList.toggle('form--loading');
                store.commit('setMessageText', response.data.message);
                store.commit('setShowMessage', true);
                return response.data;
            }

            messageTemplate = messageTemplate.replace(/%message%/, response.data.message);
            messageTemplate = messageTemplate.replace(/%status%/, 'form-message--success');
            form.classList.toggle('form--loading');
            form.innerHTML = messageTemplate;

            // Sends the event to the Google Analytics property with
            // tracking ID GA_TRACKING_ID set by the config command in
            // the global tracking snippet.
            if (window.gtag !== undefined) {
                gtag('event', 'Ingevuld', {
                    'event_category': (gtmEvent !== undefined ? gtmEvent : 'Formulierenbeheer')
                });
            }

            Listeners.MoveToInstance.move(document.querySelector('.form-message'));
            return response.data;
        }).catch(function (error) {
            if (error.response !== undefined) {
                let message = error.response.data.message;

                let errors = error.response.data.errors;
                if (errors) {
                    message = '';

                    for (let field in errors) {
                        let messages = errors[field];

                        for (let i = 0; i < messages.length; i++) {
                            message += messages[i];

                            if (i !== messages.length) {
                                message += '<br>';
                            }
                        }
                    }
                }

                messageTemplate = messageTemplate.replace(/%message%/, message);
                messageTemplate = messageTemplate.replace(/%status%/, 'form-message--error');

                form.classList.toggle('form--loading');
                form.insertAdjacentHTML('afterbegin', messageTemplate);
                Listeners.MoveToInstance.move(document.querySelector('.form-message'));

            } else {
                //console.error(error);
                Listeners.MoveToInstance.move(document.querySelector('.form-message'));
            }
        });
    },
    mousePositionDocument: function (e) {
        let posx = 0;
        let posy = 0;

        if (!e) {
            let e = window.event;
        }

        if (e.pageX || e.pageY) {
            posx = e.pageX;
            posy = e.pageY;
        }
        else if (e.clientX || e.clientY) {
            posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
            posy = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
        } else if (e.touches[0].pageX || e.touches[0].pageY) {
            posx = e.touches[0].pageX;
            posy = e.touches[0].pageY;
        }

        return {
            x: posx,
            y: posy
        };
    },
    array_move: function (arr, old_index, new_index) {
        while (old_index < 0) {
            old_index += arr.length;
        }
        while (new_index < 0) {
            new_index += arr.length;
        }
        if (new_index >= arr.length) {
            let k = new_index - arr.length;
            while ((k--) + 1) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr;
    },
    findPos: function (obj) {
        let curleft = 0;
        let curtop = 0;

        if (obj.offsetParent) {
            do {
                curleft += obj.offsetLeft;
                curtop += obj.offsetTop;
            } while (obj = obj.offsetParent);
        }

        return {
            left: curleft,
            top: curtop
        };
    },
    mousePositionElement: function (e, target) {
        const mousePosDoc = Global.mousePositionDocument(e);
        const targetPos = Global.findPos(target);

        const posx = mousePosDoc.x - targetPos.left;
        const posy = mousePosDoc.y - targetPos.top;

        return {
            x: posx,
            y: posy
        };
    },
    mouseTarget: function (e) {
        let targ;

        if (!e) {
            e = window.event;
        }

        if (e.target) targ = e.target;
        else if (e.srcElement) targ = e.srcElement;
        if (targ.nodeType === 3) // defeat Safari bug
            targ = targ.parentNode;
        return targ;
    },
    updateQueryStringParameter: function (uri, key, value) {
        const re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        const separator = uri.indexOf('?') !== -1 ? "&" : "?";
        if (uri.match(re)) {
            return uri.replace(re, '$1' + key + "=" + value + '$2');
        }
        else {
            return uri + separator + key + "=" + value;
        }
    },
    getQueryStringParameter: function (url, name) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },
    removeQueryStringParameter(url, parameter) {
        //prefer to use l.search if you have a location/link object
        var urlparts = url.split('?');
        if (urlparts.length >= 2) {

            var prefix = encodeURIComponent(parameter) + '=';
            var pars = urlparts[1].split(/[&;]/g);

            //reverse iteration as may be destructive
            for (var i = pars.length; i-- > 0;) {
                //idiom for string.startsWith
                if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                    pars.splice(i, 1);
                }
            }

            return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
        }
        return url;
    },
    setCookie: function (name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    },
    sortingMethods: {
        byDateAsc: function (a, b) {
            return new Date(a) - new Date(b);
        },
        byDateDesc: function (a, b) {
            return new Date(b) - new Date(a);
        }
    },
    getEmailAddressesFromString: function(inp) {
        return window.EmailAddresses.parse(inp);
    },
    components: [],
    eventBus: null,
    Init: function () {
        Global.Blazy();
        Global.Barba();
        Global.EventBus();
        Global.Vvalidate();
        Global.VueObserveVisibility();
        Global.addClickOutsideDirective();
        Global.addDigitsDirective();
    },
};

document.addEventListener("DOMContentLoaded", function () {
    Barba.Pjax.cacheEnabled = false;
    Barba.Pjax.start();
});

Barba.Dispatcher.on("transitionCompleted", function () {
    Global.Init();
});

/**
 * Toevoeging voor het correct tracken in analytics
 *
 * @see https://developers.google.com/analytics/devguides/collection/analyticsjs/single-page-applications
 */
Barba.Dispatcher.on('initStateChange', function () {
    if (Barba.HistoryManager.history.length <= 1) {
        return;
    }

    // send statics by Google Analytics(analytics.js) or Google Tag Manager
    if (typeof ga === 'function' && typeof ga.getAll === 'function') {
        var trackers = ga.getAll();
        trackers.forEach(function (tracker) {
            ga(tracker.get('name') + '.set', 'page', location.pathname);
            ga(tracker.get('name') + '.send', 'pageview');
        });
    }
});
