Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("vh-slider")) {
        Global.components.push(
            new Vue({
                el: '#vh-slider',
                store,
                data: function () {
                    return {}
                },
                computed: {},
                mounted: function () {
                    let that = this;

                    this.initializeSlider();

                    window.addEventListener('resize', function () {
                        that.slider.resize();
                    });
                },
                methods: {
                    initializeSlider: function () {

                        let elem = document.querySelector(".vh-slider");
                        this.slider = new Flickity(elem, {
                            contain: true,
                            pageDots: true,
                            wrapAround: true,
                            autoPlay: 5000,
                            prevNextButtons: false,
                        });
                    }
                },
                created: function () {
                },
                destroyed: function () {
                }
            })
        )
    }
});