Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("product-config-step-1")) {
        Global.components.push(
            new Vue({
                el: document.getElementById("product-config-step-1"),
                store,
                data: function () {
                    return {
                        nextStepAllowed: false,
                        submitUrl: window.location.origin + '/dashboard/configurator'
                    }
                },
                computed: {
                    configurator: {
                        get() {
                            return this.$store.state.configurator;
                        },
                        set: function (value) {
                            this.$store.dispatch('setConfigurator', value)
                        }
                    }
                },
                mounted: function () {
                    if (this.$refs.configID.value) {
                        this.configurator.id = this.$refs.configID.value;
                    }

                    this.fetchData();

                },
                methods: {
                    fetchData() {
                        const that = this;

                        if (this.configurator.id) {
                            API.GET(window.location.href).then(response => {
                                that.configurator = response.data;
                            });
                        }
                    },
                    submitStep() {
                        const that = this;
                        let data = JSON.parse(JSON.stringify(this.configurator));
                        ++data.step;

                        API.POST(this.submitUrl, data).then(response => {
                            that.configurator = response.data;
                            history.pushState(null, '', '/dashboard/configurator/' + that.configurator.id);
                        });
                    },
                    gotoStep1() {
                        window.location.href = this.submitUrl;
                    }
                },
                created: function () {
                },
                destroyed: function () {
                }
            })
        )
    }
});