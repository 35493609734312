const BoardOverviewInstance = {
    Init: function (list) {
        Global.components.push (
            new Vue({
                el: list,
                store,
                data: {
                    items: {}
                },
                computed: {
                },
                methods: {
                    loadData: function() {
                        const that = this;
                        let data = this.$el.dataset;

                        if (! data.hasOwnProperty('url')) {
                            return true;
                        }

                        // haal de data op
                        API.GET(data.url).then(response => {
                            if (! response.hasOwnProperty('data')) {
                                return false;
                            }
                            that.items = response.data;
                        });
                    },
                },
                mounted: function () {
                    this.loadData();
                },
                created: function () {
                },
                destroyed: function () {
                },
                watch: {
                    items: function () {
                        this.$nextTick(() => {
                            Global.BlazyInstance.revalidate();
                        })
                    }
                },
            })
        )
    },
};
