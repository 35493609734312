Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("product-filters")) {
        Global.components.push(
            new Vue({
                el: '#product-filters',
                store,
                data: function () {
                    return {
                        categoryUrl: document.getElementById("product-filters").dataset.categoryUrl,
                        filtersUrl: document.getElementById("product-filters").dataset.filtersUrl,
                        searchQuery: '',
                        categories: [],
                        selectedCategory: '',
                        filters: [],
                        selectedFilters: [],
                        selectedRanges: [],
                        loading: false,
                    }
                },
                beforeCreate() {
                    Global.eventBus.$on('initProductFilter', function(payload) {
                        this.init(payload);
                    }.bind(this));
                },
                created() {
                },
                mounted() {
                    Global.eventBus.$on('searchProducts', this.onQueryInput);
                    Global.eventBus.$on('resetProductFilter', this.reset);
                },
                destroyed() {
                },
                methods: {
                    init(params) {
                      for(var a in params) {
                          if(a == 'query') {
                              this.searchQuery = params[a];
                          } else if(a == 'category') {
                              this.selectedCategory = params[a];
                          } else if(/^filter/.test(a)) {
                              this.selectedFilters.push([a, params[a]]);
                          }
                      }

                      this.getCategories();
                    },
                    getCategories() {
                        const that = this;

                        API.GET(this.categoryUrl).then(response => {
                            if(!response) return;

                            that.categories = response.data;
                        });

                        if(!isNaN(parseInt(this.selectedCategory))) {
                            this.getFiltersByCategory();
                        }
                    },
                    getFiltersByCategory() {
                        const that = this;
                        if (this.selectedCategory !== null) {
                            this.loading = true;
                            API.DEBOUNCE('product-filters').GET(this.buildFiltersUrl()).then(response => {
                                if(!response) return;

                                that.loading = false;
                                that.filters = response.data;
                            });
                        }
                    },
                    buildFiltersUrl() {
                        let uri = new URI(this.filtersUrl);

                        if(this.searchQuery != '') {
                            uri.addSearch('query', this.searchQuery)
                        }

                        if(!isNaN(parseInt(this.selectedCategory))) {
                            uri.addSearch('category', this.selectedCategory)
                        }

                        if(this.selectedFilters.length > 0) {
                            this.selectedFilters.forEach((filter) => {
                                uri.addSearch(filter[0], filter[1]);
                            });
                        }

                        if(this.selectedRanges.length > 0) {
                            this.selectedRanges.forEach((filter) => {
                                if(filter !== null) {
                                    for(var a in filter) {
                                        uri.addSearch('filter[' + filter.id + '][' + a + ']', filter[a]);
                                    }
                                }
                            });
                        }

                        return uri.toString();
                    },
                    onQueryInput(query) {
                        this.searchQuery = query;

                        Global.eventBus.$emit('productQueryInput', this.searchQuery);

                        this.getFiltersByCategory();
                    },
                    onCategoryClick() {
                        Global.eventBus.$emit('productCategorySelect', this.selectedCategory);

                        this.getFiltersByCategory();
                    },
                    onFilterClick() {
                        Global.eventBus.$emit('productFilterSelect', {filters: this.selectedFilters, ranges: this.selectedRanges});

                        this.getFiltersByCategory();
                    },
                    reset() {
                        this.searchQuery = '';
                        this.selectedCategory = '';
                        this.filters = [];
                        this.selectedFilters = [];
                        this.selectedRanges = [];

                        this.$el.querySelectorAll('.filter-range').forEach(elem => {
                            Vue.set(this.selectedRanges, elem.dataset.index, []);
                            elem.noUiSlider.reset();
                        });

                        Global.eventBus.$emit('productFilterReset', this.selectedFilters);
                        Global.eventBus.$emit('resetProductSearch');
                    },
                },
                computed: {
                    showFilters: {
                        get: function () {
                            return this.$store.getters.showFilters
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setShowFilters', newValue)
                        }
                    },
                },
                watch: {
                },
            })
        )
    }
});

