Vue.component('filter-range', {
    props: [ 'filter', 'index', 'filterValues', 'loading' ],
    data: function () {
        return {
            slider: null,
        }
    },
    mounted() {
        let that = this;

        this.slider = this.$refs.slider[0];
        if(typeof(this.slider.noUiSlider) != 'undefined') {
            this.slider.noUiSlider.destroy();
        }

        noUiSlider.create(this.slider, {
            start: [ this.filter.values.min, this.filter.values.max],
            connect: true,
            step: .01,
            range: {
                'min': this.filter.bounds.min,
                'max': this.filter.bounds.max
            }
        });

        // Set values and update sizes on change
        this.slider.noUiSlider.on('update', (values) => {
            if(that.minField){
                that.minField.value = values[0];
                that.stretchInput(that.minField);
            }

            if(that.maxField) {
                that.maxField.value = values[1];
                that.stretchInput(that.maxField);
            }
        });

        this.slider.noUiSlider.on('set', (values) => {
            if(typeof that.filterValues[that.index] == 'undefined') {
                Vue.set(that.filterValues, that.index, {});
            }

            Vue.set(that.filterValues[that.index], 'id', that.filter.id);
            Vue.set(that.filterValues[that.index], 'min', values[0]);
            Vue.set(that.filterValues[that.index], 'max', values[1]);

            that.$emit('change', {
                id: that.filter.id,
                index: that.index,
                values: values,
            });
        });
    },
    computed: {
        minField() {
            if(this.$refs.min && this.$refs.min[0]) {
                return this.$refs.min[0];
            }
            return null;
        },
        maxField() {
            if(this.$refs.max && this.$refs.max[0]) {
                return this.$refs.max[0];
            }
            return null;
        },
    },
    watch: {
        filterValues: {
            deep: true,
            handler() {
                if(this.minField) {
                    if(typeof this.filterValues[this.index] != 'undefined' && typeof this.filterValues[this.index].min != 'undefined' ) {
                        this.minField.value = this.filterValues[this.index].min;
                    } else {
                        this.minField.value = this.filter.bounds.min;
                    }

                    this.stretchInput(this.minField);
                    this.slider.noUiSlider.set([ this.minField.value, null ], false);
                }

                if(this.maxField) {
                    if(typeof this.filterValues[this.index] != 'undefined' && typeof this.filterValues[this.index].max != 'undefined' ) {
                        this.maxField.value = this.filterValues[this.index].max;
                    } else {
                        this.maxField.value = this.filter.bounds.max;
                    }

                    this.stretchInput(this.maxField);
                    this.slider.noUiSlider.set([ null, this.maxField.value ], false);
                }
            }
        }
    },
    methods: {
        stretchInput(element) {
            let cs = getComputedStyle(element);
            let offset = 0;

            if (!element.value && element.placeholder) {
                let empty = true;
                element.value = element.placeholder;
            }

            element.style.width = "auto";

            if (cs.boxSizing == "border-box") {
                offset = element.offsetWidth;
            }
            else if (cs.boxSizing == "padding-box") {
                offset = element.clientWidth;
            }

            // Safari misreports scrollWidth, so we will instead set scrollLeft to a
            // huge number, and read that back to see what it was clipped to
            element.scrollLeft = 1e+10;

            var width = Math.max(element.scrollLeft + offset, element.scrollWidth - element.clientWidth) + 13;

            element.style.width = width + "px";
        }
    },
});

/**
 *
 */
