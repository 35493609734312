Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("accountmanager-overview")) {
        Global.components.push(
            new Vue({
                el: '#accountmanager-overview',
                store,
                data: function () {
                    return {
                        applicationLoading: true,
                        accountmanagersLoading: true,
                        accountmanagersData: [],
                        accountmanagersUrl: null,
                        customersLoading: true,
                        customersData: [],
                        customersUrl: null,
                        accountmanager: null,
                        query: null,
                        queryKeyUpTimer: null
                    }
                },
                created() {

                },
                beforeMount() {
                    if(this.$el.dataset !== null) {
                        for(var a in this.$el.dataset) {
                            if(this.$data.hasOwnProperty(a)) {
                                this.$data[a] = this.$el.dataset[a];
                            }
                        }
                    }

                    this.getAccountmanagers();
                    this.getCustomers();

                },
                mounted() {
                },
                watch: {
                    accountmanagersLoading(newValue) {
                        if(this.applicationLoading && !newValue && !this.customersLoading) {
                            this.applicationLoading = false;
                        }
                    },
                    customersLoading(newValue) {
                        if(this.applicationLoading && !newValue && !this.accountmanagersLoading) {
                            this.applicationLoading = false;
                        }
                    }
                },
                computed: {
                    accountmanagers() {
                        if(this.accountmanagersData !== null && this.accountmanagersData.hasOwnProperty('data')) {
                            return this.accountmanagersData.data;
                        }

                        return [];
                    },
                    customers() {
                        if(this.customersData !== null && this.customersData.hasOwnProperty('data')) {
                            return this.customersData.data;
                        }

                        return [];
                    }
                },
                methods: {
                    getAccountmanagers() {
                        this.accountmanagersLoading = true;

                        let url = this.accountmanagersUrl;

                        API.GET(url).then(response => {
                            this.accountmanagersData = response;
                            this.accountmanagersLoading = false;
                        });
                    },
                    getCustomers(page) {

                        if(typeof(page) == 'undefined') {
                            page = 1;
                        }

                        this.customersLoading = true;

                        let url = this.customersUrl;

                        // Page number
                        url = Global.updateQueryStringParameter(url, 'page', page);

                        // Accountmanager ID
                        let accountmanager = parseInt(this.accountmanager);
                        if(!isNaN(accountmanager) && accountmanager > 0) {
                            url = Global.updateQueryStringParameter(url, 'accountmanager', accountmanager);
                        }

                        // Search query
                        let query = (this.query || '').trim();
                        if(query != '') {
                            url = Global.updateQueryStringParameter(url, 'query', query);
                        }

                        // Execute XHR request
                        API.GET(url).then(response => {
                            this.customersData = response;

                            this.customersLoading = false;
                        });
                    },
                    gotoCustomerPage(page) {
                        this.getCustomers(page);
                    },
                    onQueryKeyup() {
                        this.customersLoading = true;

                        clearTimeout(this.queryKeyUpTimer);
                        this.queryKeyUpTimer = setTimeout(function() {
                            this.getCustomers();
                            this.queryKeyUpTimer = null;
                        }.bind(this), 500);
                    },
                    onItemClick(item) {
                        Global.eventBus.$emit('showCustomerActivities', item.id);
                    },
                    onActivityClick() {
                        Global.eventBus.$emit('showGlobalActivities');
                    }
                }
            })
        );
    }
});
