let APICancelable = {
    namespace: '',
    cancelSource: null,
    cancelToken: null,
    create(namespace) {
        // Create object from API and this
        let cancelable = Object.assign({}, API);
        cancelable = Object.assign(cancelable, this);

        // Clear namespaces
        cancelable.NAMESPACES = null;

        // Set namespace
        cancelable.namespace = namespace;

        // Create source
        cancelable.cancelSource = axios.CancelToken.source();

        // Save token
        cancelable.cancelToken = cancelable.cancelSource.token;

        // Register instance to namespace
        API.NAMESPACES[namespace] = cancelable;

        return cancelable;
    },

    // Can be called to cancel current API request
    cancel() {
        if(!this.cancelSource)
            return;

        this.cancelSource.cancel('Debounced...');
    },

    // Clear namespace when request is done
    done() {
        if(API.NAMESPACES[this.namespace] !== this)
            return;

        API.NAMESPACES[this.namespace] = null;
    },
};
