Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("inspiration-overview")) {
        Global.components.push(
            new Vue({
                el: '#inspiration-overview',
                store,
                mixins: [ ItemLoaderMixin ],
                data: function () {
                    return {
                        dataParams: document.getElementById('inspiration-overview').dataset.params,
                        editModeActive: false,
                        editCardUrl: document.getElementById('inspiration-overview').dataset.editCardUrl,
                        addItemUrl: document.getElementById('inspiration-overview').dataset.addItemUrl,
                        createItemUrl: document.getElementById('inspiration-overview').dataset.createItemUrl,
                        ownItemTitle: '',
                        addedItem: null,
                        showAddOwnItemModal: false,
                        file: null,
                        uploadingInProgress: false,
                        packery: null,
                        viewerJs: null,
                        apiNamespace: 'inspiration-overview',
                    }
                },
                computed: {
                    showSavePopup: {
                        get: function () {
                            return this.$store.getters.showSavePopup
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setShowSavePopup', newValue)
                        }
                    },
                    saveDataUrl: {
                        get: function () {
                            return this.$store.getters.saveDataUrl
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setSaveDataUrl', newValue)
                        }
                    },
                    saveDataType: {
                        get: function () {
                            return this.$store.getters.saveDataType
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setSaveDataType', newValue)
                        }
                    },
                    messageText: {
                        get: function () {
                            return this.$store.getters.messageText
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setMessageText', newValue)
                        }
                    },
                    refreshFloorplan: {
                        get: function () {
                            return this.$store.state.refreshFloorplan
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setRefreshFloorplan', newValue);
                        }
                    }
                },
                watch: {
                    editModeActive: function () {
                        if (this.editModeActive) {
                            let grid = this.$refs.grid;

                            this.packery = new Packery(grid, {
                                columnWidth: '.large-6',
                                itemSelector: '.cell',
                                percentPosition: true
                            });

                            let draggableItems = grid.querySelectorAll('.cell');
                            for (let i = 0; i < draggableItems.length; i++) {
                                let draggableItem = draggableItems[i];
                                this.packery.bindDraggabillyEvents(new Draggabilly(draggableItem));
                            }

                        } else if (this.packery !== null) {
                            this.orderItems();
                        }
                    },

                    items: function () {
                        let cards = this.$el.querySelectorAll('.inspiration-card');
                        if (cards.length) {
                            cards.forEach((card) => {
                                let text = card.querySelector('.inspiration-card__text');
                                if (text) {
                                    $clamp(text, {clamp: 4});
                                }
                            });
                        }

                        setTimeout(() => {
                            this.viewerJs.update();
                        });
                    }
                },
                created: function () {
                    this.dataParams = null;
                    this.editCardUrl = null;
                    this.addItemUrl = null;
                    this.createItemUrl = null;

                    this.defaultCallback = (response) => {
                        if(this.data !== null && this.data.hasOwnProperty('data')) {
                            this.data.data.forEach(item => {
                                this.setCardOriginal(item);
                                this.checkIfcardIsDirty(item);
                            });
                        }
                    };
                },
                mounted: function () {
                    this.dataParams = document.getElementById('inspiration-overview').dataset.params;
                    this.editCardUrl = document.getElementById('inspiration-overview').dataset.editCardUrl;
                    this.addItemUrl = document.getElementById('inspiration-overview').dataset.addItemUrl;
                    this.createItemUrl = document.getElementById('inspiration-overview').dataset.createItemUrl;

                    this.startViewerJs();

                    Global.eventBus.$on('searchInspiration', function(payload) {
                        this.searchInspiration(payload);
                    }.bind(this))
                },

                methods: {
                    startViewerJs: function() {
                        this.viewerJs = new Viewer(document.getElementById('viewerjs'), {
                            backdrop: true,
                            button: true,
                            navbar: false,
                            title: function(image) {
                                return image.alt;
                            },
                            toolbar: {
                                zoomIn: true,
                                zoomOut: true,
                                oneToOne: true,
                                reset: true,
                                prev: true,
                                play: false,
                                next: true,
                                rotateLeft: false,
                                rotateRight: false,
                                flipHorizontal: false,
                                flipVertical: false,
                            },
                            fullscreen: true,
                            keyboard: true,
                            loading: true,
                            loop: true,
                            movable: true,
                            zoomable: true,
                            rotatable: false,
                            scalable: false,
                            toggleOnDblclick: true,
                            tooltip: true,
                            transition: true,
                            url: function(image) {
                                if(typeof(image.dataset) != 'undefined' && typeof(image.dataset.src) != 'undefined' && image.dataset.src != null) {
                                    return image.dataset.src;
                                }
                                return image.src;
                            }

                        });
                    },
                    openViewerJs: function(event, index) {
                        if(this.items[index].source == 'attachment' && this.items[index].image_original) {

                            // ViewerJS index achterhalen
                            let viewerJsTotal = 0;
                            let viewerJsIndex = 0;
                            for(let i = 0 ; i < this.items.length ; i++) {
                                if(this.items[i].source == 'attachment' && this.items[i].image_original) {
                                    viewerJsTotal = viewerJsTotal + 1;
                                    if(i == index) {
                                        viewerJsIndex = (viewerJsTotal-1);
                                    }
                                }
                            }

                            this.viewerJs.view(viewerJsIndex);

                            event.preventDefault();
                        }
                    },
                    searchInspiration: function(query) {

                        this.params.query = query;
                        this.params.page = 1;

                        this.loadData();
                    },
                    processFile(event) {
                        const that = this;
                        this.file = event.target.files;

                        for (let i = 0; i < this.file.length; i++) {
                            let formData = new FormData();
                            formData.append('file', this.file.item(i));

                            that.uploadOwnItem(formData);
                        }
                    },
                    uploadOwnItem(data) {
                        const that = this;
                        this.uploadingInProgress = true;

                        API.POST(this.addItemUrl, data).then(response => {
                            that.uploadingInProgress = false;
                            that.addedItem = response.data;
                            that.messageText = response.message;
                        });
                    },
                    addItem() {
                        const that = this;
                        this.uploadingInProgress = true;

                        let formData = new FormData();
                        formData.append('title', this.ownItemTitle);
                        formData.append('hash', this.addedItem.id);

                        API.POST(this.createItemUrl, formData).then(response => {
                            that.uploadingInProgress = false;
                            that.ownItemTitle = '';
                            that.showAddOwnItemModal = false;
                            // response verwerken
                            that.messageText = response.message;

                            if (response.hasOwnProperty('layoutIndex')) {
                                that.layoutIndex = response.layoutIndex;
                            }

                            if (response.hasOwnProperty('attachmentsAllowed')) {
                                that.attachmentsAllowed = response.attachmentsAllowed;
                            }

                            that.data = response;

                            // Store data in storage
                            that.saveDataInStorage();

                            if(that.data !== null && that.data.hasOwnProperty('data')) {
                                that.data.data.forEach(item => {
                                    that.setCardOriginal(item);
                                    that.checkIfcardIsDirty(item);
                                });
                            }
                        });
                    },
                    checkCardForOriginal: function(item) {
                        if(!item.hasOwnProperty('original')) {
                            this.setCardOriginal(item);
                        }
                    },
                    setCardOriginal: function(item) {
                        item.original = {
                            title: item.title,
                            description: item.description
                        };
                    },
                    checkIfcardIsDirty: function(item) {
                        item.dirty = (item.title != item.original.title || item.description != item.original.description);
                        this.$forceUpdate();
                    },
                    checkCard: function(item) {
                        this.checkCardForOriginal(item);
                        this.checkIfcardIsDirty(item);
                    },
                    editCard: function (item) {
                        const that = this;

                        let editCardUrl = this.editCardUrl.replace('::itemID::', item.id);

                        let formData = new FormData();
                        formData.append('_method', 'put');

                        if (item.hasOwnProperty('title')) {
                            formData.append('title', item.title);
                        }

                        if (item.hasOwnProperty('description')) {
                            formData.append('description', item.description);
                        }

                        API.POST(editCardUrl, formData).then(response => {

                            that.setCardOriginal(item);
                            that.checkIfcardIsDirty(item);

                            that.messageText = response.message;
                        });
                    },
                    removeCard: function (id, msg) {
                        const that = this;

                        if (confirm(msg) !== true) {
                            return false;
                        }

                        let editCardUrl = this.editCardUrl.replace('::itemID::', id);

                        let formData = new FormData();
                        formData.append('_method', 'delete');

                        API.POST(editCardUrl, formData).then(response => {
                            that.messageText = response.message;
                            that.refreshFloorplan = true;
                            this.getInspiration(1, false);
                        });
                    },
                    orderItems: function () {
                        if (this.packery === null) {
                            return;
                        }

                        let items = this.packery.getItemElements();
                        let max = items.length * 100;
                        let positions = [];

                        items.forEach(item => {
                            positions.push({
                                'id': item.dataset.id,
                                'position': max
                            });

                            max = max - 10;
                        });

                        let formData = new FormData();
                        formData.append('_method', 'put');
                        formData.append('positions', JSON.stringify(positions));

                        API.POST(window.location.href, formData);
                    }
                }
            })
        )
    }
});
