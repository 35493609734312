Vue.component('creation-title', {
    props: ['title', 'updateUrl'],
    data() {
        return {
            editing: false
        }
    },
    template: `
        <div class="product-config-title">
            <div v-show="!editing">
                <h3 class="product-config-title__title" v-show="!editing">{{ title }}</h3>
                <a class="icon-link" @click="editTitle">Wijzigen</a>
            </div>
            <div v-show="editing">
                <input class="product-config-title__input" type="text" ref="title" v-model="title" autofocus/>
                <a class="icon-link" @click="saveTitle">Opslaan</a>
            </div>
        </div>
    `,
    methods: {
        editTitle() {
            this.editing = true;
        },
        saveTitle() {
            this.editing = false;
            API.PUT(this.updateUrl, {'title': this.title});
        }
    }
});
