Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("product-config-finished")) {
        Global.components.push(
            new Vue({
                el: document.getElementById("product-config-finished"),
                store,
                data: function () {
                    return {
                        configuratorUrl: window.location.origin + '/dashboard/configurator',
                        currencyFormatter: new Intl.NumberFormat('nl-NL', {
                            style: 'currency',
                            currency: 'EUR'
                        })
                    }
                },
                computed: {
                    totalPrice() {
                        let total = [];

                        this.configurator.contents.forEach(elem => {
                            total.push(elem.price);
                        });

                        return total.reduce(function (total, num) {
                            return total + num
                        }, 0);
                    },
                    configurator: {
                        get() {
                            return this.$store.state.configurator;
                        },
                        set: function (value) {
                            this.$store.dispatch('setConfigurator', value)
                        }
                    },
                    messageText: {
                        get: function () {
                            return this.$store.getters.messageText
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setMessageText', newValue)
                        }
                    },
                    showSavePopup: {
                        get: function () {
                            return this.$store.getters.showSavePopup
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setShowSavePopup', newValue)
                        }
                    },
                    saveDataUrl: {
                        get: function () {
                            return this.$store.getters.saveDataUrl
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setSaveDataUrl', newValue)
                        }
                    },
                    saveDataType: {
                        get: function () {
                            return this.$store.getters.saveDataType
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setSaveDataType', newValue)
                        }
                    }
                },
                watch: {},
                mounted: function () {
                },
                methods: {
                    addToShoppingcart(id) {
                    }
                },
                created: function () {
                },
                destroyed: function () {
                }
            })
        )
    }
});
