Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("convert-moodboard")) {
        Global.components.push(
            new Vue({
                el: '#convert-moodboard',
                store,
                data: function () {
                    return {
                        showConversionModal: false,
                        conversionSuccessfull: false,
                        responseMessage: '',
                        responseUrl: '#'
                    }
                },
                mounted: function () {
                },
                computed: {
                    showSavePopup: {
                        get: function () {
                            return this.$store.getters.showSavePopup
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setShowSavePopup', newValue)
                        }
                    },
                    saveDataUrl: {
                        get: function () {
                            return this.$store.getters.saveDataUrl
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setSaveDataUrl', newValue)
                        }
                    },
                    saveDataType: {
                        get: function () {
                            return this.$store.getters.saveDataType
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setSaveDataType', newValue)
                        }
                    }
                },
                methods: {
                    convertToProject: function (url, moodboardID) {
                        const that = this;
                        let formData = new FormData();
                        formData.append('moodboard', moodboardID);

                        API.POST(url, formData).then(response => {
                            that.conversionSuccessfull = true;
                            that.responseMessage = response.message;
                            that.responseUrl = response.target;
                        });
                    }
                },
                created: function () {
                },
                destroyed: function () {
                }
            })
        )
    }
});
