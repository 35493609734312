Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("step-numbers")) {
        Global.components.push(
            new Vue({
                el: '#step-numbers',
                store,
                data: function () {
                    return {}
                },
                computed: {
                    registerStepClass: {
                        get: function () {
                            return "step-numbers--slide-" + this.$store.getters.registerStep
                        }
                    },
                },
                mounted: function () {
                },
                methods: {},
                created: function () {
                },
                destroyed: function () {
                }
            })
        )
    }
});