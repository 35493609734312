let ItemLoaderMixin = {
    mixins: [ DebugMixin ],
    data() {
        return {
            url: null,
            loading: true,
            data: null,
            params: {},
            defaultCallback: null,
            scrolled: 0,
            scrollLocation: 0,
            lazyload: false,
            storage: (typeof(window.sessionStorage) !== "undefined" ? window.sessionStorage : false),
            key: 'item-loader',
            apiNamespace: 'item-loader',
        }
    },
    created() {
        this.debug('[ItemLoader] created');

        this.loadUrlFromDataset();

        this.loadParamsFromUrl();
        this.setWindowLocationParams();
        this.loadPreviousScrollPosition();

        this.loadData(() => {
            this.moveToPreviousScrollPosition();
        });
    },
    mounted() {
        this.debug('[ItemLoader] mounted');

        window.addEventListener('scroll', this.onScroll);
    },
    destroyed() {
        this.debug('[ItemLoader] destroyed');

        window.removeEventListener('scroll', this.onScroll);
    },
    computed: {
        items() {
            if(this.data !== null && this.data !== undefined && this.data.hasOwnProperty('data')) {
                return this.data.data;
            }
            return null
        },
        meta() {
            if(this.data !== null && this.data !== undefined  && this.data.hasOwnProperty('meta')) {
                return this.data.meta;
            }
            return null
        },
        layoutIndex() {
            if(this.data !== null && this.data !== undefined  && this.data.hasOwnProperty('layoutIndex')) {
                return this.data.layoutIndex;
            }
            return null
        },
        attachmentsAllowed() {
            if(this.data !== null && this.data !== undefined  && this.data.hasOwnProperty('attachmentsAllowed')) {
                return this.data.attachmentsAllowed;
            }
            return false
        },
        currentPage() {
            let fromMeta = parseInt((this.meta !== null ? this.meta['current_page'] : null));
            let fromUrl = parseInt(Global.getQueryStringParameter(window.location.href, 'page'));

            if(!isNaN(fromMeta)) {
                return fromMeta;
            } else if(!isNaN(fromUrl)) {
                return fromUrl;
            }

            return 1;
        },
        nextPage() {
            return (this.currentPage + 1);
        },
        hasMorePages() {
            return (this.meta !== null ? this.meta['last_page'] >= this.nextPage : false);
        },
    },
    methods: {
        loadUrlFromDataset() {
          this.url = document.querySelector(this.$options.el).dataset.url;
            this.debug('[ItemLoader] loadUrlFromDataset; found: "' + this.url + '"');

            let lazyload = (document.querySelector(this.$options.el).dataset.lazyload || null);
            if(lazyload !== null) {
                this.lazyload = (lazyload == 'true');
            }
        },
        loadParamsFromUrl() {
            let uri = new URI(window.location);
            this.params = uri.search(true);

            if(this.params.hasOwnProperty('restore')) {
                this.loadPreviousParamsFromStorage();
            }

            this.debug('[ItemLoader] loadParamsFromUrl; found: ' + JSON.stringify(this.params));
        },
        loadPreviousParamsFromStorage() {
            if(this.storage !== false) {

                let storageKey = this.makeParamsStorageParameters();

                let itemData = JSON.parse((this.storage.getItem(storageKey) || '{}'));

                if(itemData.hasOwnProperty('restore')) {
                    delete(itemData['restore']);
                }

                this.params = itemData;
            } else {
                this.debug('[ItemLoader] loadPreviousParamsFromStorage; storage not supported');
            }
        },
        saveCurrentParams() {
            if(this.storage !== false) {

                let storageKey = this.makeParamsStorageParameters();

                let itemData = this.params;

                this.storage.setItem(storageKey, JSON.stringify(itemData));
            } else {
                this.debug('[ItemLoader] saveCurrentParams; storage not supported');
            }
        },
        makeParamsStorageParameters() {
            let uri = new URI(window.location);

            return this.key + '-params' + uri.path().replace(/[^a-z0-9]/g, '-');
        },
        setWindowLocationParams() {
            let uri = new URI(window.location);
            let currentLocation = uri.toString();

            uri.search(this.params);
            let newLocation = uri.toString();

            if(currentLocation != newLocation) {
                window.history.replaceState({}, document.title, newLocation);

                this.debug('[ItemLoader] setWindowLocationParams; navigated to: "' + newLocation + '"');
            }
        },
        loadData(callback) {
            this.getData(false, callback);
        },
        appendData(callback) {
            this.getData(true, callback);
        },
        getData(append, callback) {
            let that = this;

            if(typeof(append) == 'undefined') {
                append = true;
            }

            if(typeof(callback) == 'undefined') {
                callback = this.defaultCallback;
            }

            this.setWindowLocationParams();
            this.saveCurrentParams();

            let uri = new URI(this.url);
            uri.search(this.params);

            uri.addSearch('append', (append ? 1 : 0));

            let url = uri.toString();

            this.debug('[ItemLoader] getData; fetching: "' + url + '"');

            // Set loading
            this.loading = true;

            // Execute request
            API.DEBOUNCE(this.apiNamespace).GET(url).then(response => {
                this.debug('[ItemLoader] getData; recieved response');

                if(typeof(response) == 'object') {
                    if(append && this.data !== null) {
                        for(var a in response) {
                            if((a == 'data' || a == 'layoutIndex') && typeof(this.data[a]) != 'undefined') {
                                this.data[a] = this.data[a].concat(response[a]);
                            } else {
                                this.data[a] = response[a];
                            }
                        }
                    } else {
                        this.data = response;
                    }

                    this.debug('[ItemLoader] getData; response has beend processed');

                    // Callback
                    if(callback != null) {
                        this.debug('[ItemLoader] getData; executing callback');
                        callback.call(this, response);
                    }

                    this.debug('[ItemLoader] getData; done');
                } else {
                    this.debug('[ItemLoader] getData; response is not a valid object');
                }

                that.loading = false;
            });
        },
        resetData() {

        },
        goToNextPage() {
            this.scrollLocation = window.pageYOffset;

            this.params.page = this.nextPage;

            this.appendData(() => {
                this.$nextTick(() => {
                    window.scrollTo(0, this.scrollLocation);
                });
            });
        },
        onScroll() {
            this.saveCurrentScrollPosition();

            this.checkLazyload();
        },
        loadPreviousScrollPosition() {
            if(this.storage !== false) {

                let entryParam = this.makeScrollPositionStorageParameters('scrolled');
                if(entryParam.subKey == '') {
                    return;
                }

                let itemData = JSON.parse((this.storage.getItem(entryParam.mainKey) || '{}'));

                if (itemData.hasOwnProperty(entryParam.subKey)) {
                    this.scrolled = itemData[entryParam.subKey];

                    this.debug('[ItemLoader] loadPreviousScrollPosition; found position: ' + this.scrolled);
                } else {
                    this.debug('[ItemLoader] loadPreviousScrollPosition; no previous scroll position found');
                }
            } else {
                this.debug('[ItemLoader] loadPreviousScrollPosition; storage not supported');
            }
        },
        moveToPreviousScrollPosition() {
            this.$nextTick(() => {
                this.debug('[ItemLoader] moveToPreviousScrollPosition; moving window to position: ' + this.scrolled);

                window.scrollTo(0, this.scrolled);
            });
        },
        makeScrollPositionStorageParameters() {
            let uri = new URI(window.location);

            return {
                mainKey: this.key + '-scrolled' + uri.path().replace(/[^a-z0-9]/g, '-'),
                subKey: uri.query()
            };
        },
        saveCurrentScrollPosition() {
            if(this.storage !== false) {

                let entryParam = this.makeScrollPositionStorageParameters();
                if(entryParam.subKey == '') {
                    return;
                }

                let itemData = JSON.parse((this.storage.getItem(entryParam.mainKey) || '{}'));

                itemData[entryParam.subKey] = window.scrollY;

                this.storage.setItem(entryParam.mainKey, JSON.stringify(itemData));
            } else {
                this.debug('[ItemLoader] saveCurrentScrollPosition; storage not supported');
            }
        },
        checkLazyload() {
            if(this.lazyload && !this.loading && this.hasMorePages) {
                let overviewBoundary = this.$el.getBoundingClientRect();

                if (window.innerHeight > (overviewBoundary.height + overviewBoundary.top)) {

                    this.debug('[ItemLoader] checkLazyload; executing request for next page');

                    this.params.page = this.nextPage;

                    this.appendData();
                }
            }
        }
    }
}
