var store = new Vuex.Store({
    state: {
        isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
        menuExpanded: false,
        registerStep: null,
        activeBoardFilter: null,
        showBoardArchive: false,
        moodboardItems: [],
        projectItems: [],
        creationItems: [],
        boardItems: null,
        projectFloorplans: null,
        activeFloorplan: null,
        refreshFloorplan: false,
        searchResults: 0,
        showSavePopup: false,
        pointerSelected: null,
        saveDataUrl: null,
        saveDataType: null,
        messageText: '',
        showMessage: false,
        showAddPointerPopup: false,
        openedPointer: null,
        newPointer: false,
        showEditFloorplansModal: false,
        mousePosition: {},
        inSearchMode: (Global.getQueryStringParameter(window.location.href, 'query') !== null),
        searchQuery: Global.getQueryStringParameter(window.location.href, 'query') !== null ? Global.getQueryStringParameter(window.location.href, 'query'): '',
        errorObject: null,
        showError: false,
        showFilters: false,
        productsQuery: {},
        configurator: {
            step: 1,
            container: {
                type: ''
            },
            contents: []
        },
        configuratorProductRemoved: false,
        configuratorStep2SubmitFormStepAllowed: false,
        configuratorExternalSubmit: null,
        showOrderBlockedMessage: false
    },
    actions: {
        toggleMenu({commit}, value) {
            commit('toggleMenu', value)
        },
        setRegisterStep({commit}, value) {
            commit('setRegisterStep', value)
        },
        setActiveBoardFilter({commit}, value) {
            commit('setActiveBoardFilter', value)
        },
        setShowBoardArchive({commit}, value) {
            commit('setShowBoardArchive', value)
        },
        setSearchResults({commit}, value) {
            commit('setSearchResults', value)
        },
        setMoodboardItems({commit}, value) {
            commit('setMoodboardItems', value)
        },
        setProjectItems({commit}, value) {
            commit('setProjectItems', value)
        },
        setCreationItems({commit}, value) {
            commit('setCreationItems', value)
        },
        setShowSavePopup({commit}, value) {
            commit('setShowSavePopup', value)
        },
        setPointerSelected({commit}, value) {
            commit('setPointerSelected', value)
        },
        setSaveDataUrl({commit}, value) {
            commit('setSaveDataUrl', value)
        },
        setSaveDataType({commit}, value) {
            commit('setSaveDataType', value)
        },
        setBoardItems({commit}, value) {
            commit('setBoardItems', value)
        },
        setProjectFloorplans({commit}, value) {
            commit('setProjectFloorplans', value)
        },
        setActiveFloorplan({commit}, value) {
            commit('setActiveFloorplan', value)
        },
        setShowEditFloorplansModal({commit}, value) {
            commit('setShowEditFloorplansModal', value)
        },
        setMessageText({commit}, value) {
            commit('setMessageText', value)
        },
        setShowMessage({commit}, value) {
            commit('setShowMessage', value)
        },
        setShowAddPointerPopup({commit}, value) {
            commit('setShowAddPointerPopup', value)
        },
        setOpenedPointer({commit}, value) {
            commit('setOpenedPointer', value)
        },
        setNewPointer({commit}, value) {
            commit('setNewPointer', value)
        },
        setSearchQuery({commit}, value) {
            commit('setSearchQuery', value)
        },
        setInSearchMode({commit}, value) {
            commit('setInSearchMode', value)
        },
        setMousePosition({commit}, value) {
            commit('setMousePosition', value)
        },
        setShowAddFloorplanModal({commit}, value) {
            commit('setShowAddFloorplanModal', value)
        },
        setRefreshFloorplan({commit}, value) {
            commit('setRefreshFloorplan', value)
        },
        setErrorObject({commit}, value) {
            commit('setErrorObject', value)
        },
        setShowError({commit}, value) {
            commit('setShowError', value)
        },
        setShowFilters({commit}, value) {
            commit('setShowFilters', value)
        },
        setProductsQuery({commit}, value) {
            commit('setProductsQuery', value)
        },
        setConfigurator({commit}, value) {
            commit('setConfigurator', value)
        },
        setConfiguratorProductRemoved({commit}, value) {
            commit('setConfiguratorProductRemoved', value)
        },
        setConfiguratorStep2SubmitFormStepAllowed({commit}, value) {
            commit('setConfiguratorStep2SubmitFormStepAllowed', value)
        },
        setConfiguratorExternalSubmit({commit}, value) {
            commit('setConfiguratorExternalSubmit', value)
        },
        setShowOrderBlockedMessage({commit}, value) {
            commit('setShowOrderBlockedMessage', value)
        }
    },
    getters: {
        isMobile() {
            return store.state.isMobile;
        },
        menuExpanded() {
            return store.state.menuExpanded;
        },
        registerStep() {
            return store.state.registerStep;
        },
        activeBoardFilter() {
            return store.state.activeBoardFilter;
        },
        showBoardArchive() {
            return store.state.showBoardArchive;
        },
        projectItems() {
            return store.state.projectItems;
        },
        moodboardItems() {
            return store.state.moodboardItems;
        },
        creationItems() {
            return store.state.creationItems;
        },
        searchResults() {
            return store.state.searchResults;
        },
        showSavePopup() {
            return store.state.showSavePopup;
        },
        pointerSelected() {
            return store.state.pointerSelected;
        },
        saveDataUrl() {
            return store.state.saveDataUrl;
        },
        saveDataType() {
            return store.state.saveDataType;
        },
        projectFloorplans() {
            return store.state.projectFloorplans;
        },
        activeFloorplan() {
            return store.state.activeFloorplan;
        },
        showEditFloorplansModal() {
            return store.state.showEditFloorplansModal;
        },
        messageText() {
            return store.state.messageText;
        },
        showMessage() {
            return store.state.showMessage;
        },
        showAddPointerPopup() {
            return store.state.showAddPointerPopup;
        },
        openedPointer() {
            return store.state.openedPointer;
        },
        newPointer() {
            return store.state.newPointer;
        },
        inSearchMode() {
            return store.state.inSearchMode;
        },
        searchQuery() {
            return store.state.searchQuery;
        },
        mousePosition() {
            return store.state.mousePosition;
        },
        showAddFloorplanModal() {
            return store.state.showAddFloorplanModal;
        },
        errorObject() {
            return store.state.errorObject;
        },
        showError() {
            return store.state.showError;
        },
        showFilters() {
            return store.state.showFilters;
        },
        productsQuery() {
            return store.state.productsQuery;
        },
        showOrderBlockedMessage() {
            return store.state.showOrderBlockedMessage;
        },
    },
    mutations: {
        toggleMenu(state, value) {
            store.state.menuExpanded = value;
        },
        setRegisterStep(state, value) {
            store.state.registerStep = value;
        },
        setActiveBoardFilter(state, value) {
            store.state.activeBoardFilter = value;
        },
        setShowBoardArchive(state, value) {
            store.state.showBoardArchive = value;
        },
        setMoodboardItems(state, value) {
            store.state.moodboardItems = value;
        },
        setProjectItems(state, value) {
            store.state.projectItems = value;
        },
        setCreationItems(state, value) {
            store.state.creationItems = value;
        },
        setSearchResults(state, value) {
            store.state.searchResults = value;
        },
        setShowSavePopup(state, value) {
            store.state.showSavePopup = value;
        },

        setPointerSelected(state, value) {
            store.state.pointerSelected = value;
        },

        setSaveDataUrl(state, value) {
            store.state.saveDataUrl = value;
        },
        setSaveDataType(state, value) {
            store.state.saveDataType = value;
        },
        setProjectFloorplans(state, value) {
            store.state.projectFloorplans = value;
        },
        setActiveFloorplan(state, value) {
            store.state.activeFloorplan = value;
        },
        setRefreshFloorplan(state, value) {
            store.state.refreshFloorplan = value;
        },
        setMessageText(state, value) {
            store.state.messageText = value;
        },
        setShowMessage(state, value) {
            store.state.showMessage = value;
        },
        setShowAddPointerPopup(state, value) {
            store.state.showAddPointerPopup = value;
        },
        setOpenedPointer(state, value) {
            store.state.openedPointer = value;
        },
        setNewPointer(state, value) {
            store.state.newPointer = value;
        },
        setInSearchMode(state, value) {
            store.state.inSearchMode = value;
        },
        setSearchQuery(state, value) {
            store.state.searchQuery = value;
        },
        setMousePosition(state, value) {
            store.state.mousePosition = value;
        },
        setShowEditFloorplansModal(state, value) {
            store.state.showEditFloorplansModal = value;
        },
        setErrorObject(state, value) {
            store.state.errorObject = value;
        },
        setShowError(state, value) {
            store.state.showError = value;
        },
        setShowFilters(state, value) {
            store.state.showFilters = value;
        },
        setProductsQuery(state, value) {
            store.state.productsQuery = value;
        },
        setConfigurator(state, value) {
            store.state.configurator = value;
        },
        setConfiguratorProductRemoved(state, value) {
            store.state.configuratorProductRemoved = value;
        },
        setConfiguratorStep2SubmitFormStepAllowed(state, value) {
            store.state.configuratorStep2SubmitFormStepAllowed = value;
        },
        setConfiguratorExternalSubmit(state, value) {
            store.state.configuratorExternalSubmit = value;
        },
        setShowOrderBlockedMessage(state, value) {
            store.state.showOrderBlockedMessage = value;
        },
    }
});
