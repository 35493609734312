Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("share-board-or-project")) {
        Global.components.push(
            new Vue({
                el: '#share-board-or-project',
                store,
                data: function () {
                    return {
                        showShareModal: false,
                        endpoint: document.getElementById("share-board-or-project").dataset.url,
                        loaded: false,
                        share: null,
                        emails: null,
                        checkEmailInputOnNextKeyUp: false,
                        inputEmailIsFocussed: false,
                        showEmailPlaceholder: true,
                        isEmailLoading: false,
                        showEmailMessage: null,
                        maxEmails: 20
                    }
                },
                mounted: function () {
                    this.load();

                    this.checkIfPlaceholderIsNeeded();
                },
                computed: {
                    messageText: {
                        get: function () {
                            return this.$store.getters.messageText
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setMessageText', newValue)
                        }
                    },
                    showMessage: {
                        get: function () {
                            return this.$store.getters.showMessage
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setShowMessage', newValue)
                        }
                    },
                },
                methods: {
                    load: function() {
                        const that = this;

                        API.GET(that.endpoint).then(response => {
                            that.share = response.data;
                            that.loaded = true;
                        });
                    },
                    store: function () {
                        const that = this;

                        let formData = new FormData(that.$refs.form);

                        API.POST(that.endpoint, formData).then(response => {
                            that.share = response.data;
                            that.loaded = true;
                        });
                    },
                    copyToClipboard: function (event) {
                        event.currentTarget.select();
                        document.execCommand("Copy");

                        this.messageText = "Url gekopieërd!";
                        this.showMessage = true;
                    },
                    removeEmail: function(x) {

                        if(this.isEmailLoading) {
                            return false;
                        }

                        this.emails.splice(x, 1);

                        this.focusEmailInput();
                    },
                    checkEmailInputKeyDown: function(event) {

                        if(this.isEmailLoading) {
                            event.preventDefault();

                        }
                        this.showEmailMessage = null;

                        let value = this.$refs.email.value;

                        if(this.isPasteEvent(event)) {
                            this.checkEmailInputOnNextKeyUp = true;
                        } else if(this.isBackspaceKey(event) && value == '' && this.emails !== null && this.emails.length > 0) {
                            this.removeEmail((this.emails.length - 1));
                        }

                        // Check if we need to display the placeholder again
                        this.checkIfPlaceholderIsNeeded();

                        // Focus on the input
                        this.focusEmailInput();

                        // Adjust input width
                        this.fitEmailInput();
                    },
                    checkEmailInputKeyUp: function(event) {

                        let keyCode = event.keyCode;
                        let checkKeyCodes = [32, 188, 186, 13, 39]; // What keycodes makes us check the input for addresses

                        if(this.checkEmailInputOnNextKeyUp || checkKeyCodes.indexOf(keyCode) >= 0) {
                            this.checkEmailInput();
                        }

                        this.checkEmailInputOnNextKeyUp = false;

                        // Check if we need to display the placeholder again
                        this.checkIfPlaceholderIsNeeded();

                        // Focus on the input
                        this.focusEmailInput();

                        // Adjust input width
                        this.fitEmailInput();
                    },
                    checkEmailInputBlur: function(event) {
                        this.inputEmailIsFocussed = false;

                        this.checkEmailInput();

                        // Check if we need to display the placeholder again
                        this.checkIfPlaceholderIsNeeded();
                    },
                    checkEmailInputFocus: function(event) {
                        this.inputEmailIsFocussed = true;

                        // Check if we need to display the placeholder again
                        this.checkIfPlaceholderIsNeeded();
                    },
                    checkEmailInput: function() {

                        // Get the value from the input
                        let value = this.$refs.email.value;
                        let checkValue = this.$refs.email.value.trim();

                        if(checkValue != '') {
                            // Get all email addresses from the string
                            let foundEmails = Global.getEmailAddressesFromString(checkValue);
                            if(foundEmails != null && foundEmails.length > 0) {
                                for(let x = 0 ; x < foundEmails.length ; x++) {
                                    let foundEmail = foundEmails[x];

                                    // If the addres is not yet in our list, add it
                                    if(this.emails === null) {
                                        this.emails = [];
                                    }
                                    if(this.emails.indexOf(foundEmail) < 0) {
                                        this.emails.push(foundEmail);

                                        if(this.emails.length > this.maxEmails) {
                                            this.emails.splice(this.maxEmails);
                                        }
                                    }

                                    // Remove the address (and any attached control characters) from the input value
                                    value = value
                                        .replace(foundEmail, '')
                                        .replace(/^\s+/,"")
                                        .replace(/^,+/,"")
                                        .replace(/^;+/,"")
                                        .replace(/\s+$/,"")
                                        .replace(/,+$/,"")
                                        .replace(/;+$/,"")
                                        .replace(/,[ ]?,/,"")
                                        .replace(/;[ ]?;/,"");
                                }
                            }
                        }

                        // Put the mutated value back into the input (if changed)
                        if(this.$refs.email.value != value) {
                            this.$refs.email.value = value;
                        }

                        // Adjust input width
                        this.fitEmailInput();
                    },
                    fitEmailInput: function() {
                        this.$refs.email.size = (this.$refs.email.value.length + 1);
                    },
                    focusEmailInput: function() {
                        this.$refs.email.focus();
                    },
                    isPasteEvent: function(event) {
                        return ((event.ctrlKey || event.metaKey) && event.keyCode == 86);
                    },
                    isBackspaceKey: function(event) {
                        return (event.keyCode == 8);
                    },
                    checkIfPlaceholderIsNeeded: function() {

                        let value = '';
                        if(typeof(this.$refs.email) != 'undefined') {
                            value = this.$refs.email.value;
                        }

                        this.showEmailPlaceholder = (value == '' && (this.emails === null || this.emails.length == 0));
                    },
                    submitEmailForm: function() {
                        if(!this.isEmailLoading && this.emails !== null && this.emails.length > 0) {

                            this.isEmailLoading = true;
                            this.showEmailMessage = null;

                            const that = this;

                            let numberOfEmails = this.emails.length;
                            let numberOfRequests = 0;
                            let numberOfResponses = 0;
                            let numberOfErrors = 0;

                            let emails = JSON.parse(JSON.stringify(this.emails));
                            let iterator = 0;

                            var next = function() {
                                let formData = new FormData(that.$refs.emailForm);
                                formData.delete('sendto');
                                formData.delete('sendto[]');
                                formData.set('sendto', emails[iterator]);

                                numberOfRequests++;

                                API.POST(that.endpoint, formData).then(response => {
                                    numberOfResponses++;

                                    if(response.error) {
                                        numberOfErrors++;
                                    } else if(that.emails.indexOf(emails[iterator]) >= 0) {
                                        that.emails.splice(that.emails.indexOf(emails[iterator]), 1);
                                    }

                                    if(numberOfEmails == numberOfRequests) {
                                        that.submittedEmailForm(numberOfRequests, numberOfErrors);
                                    } else {
                                        iterator++;
                                        next()
                                    }
                                });
                            };

                            next();
                        }
                    },
                    submittedEmailForm: function(numberOfRequests, numberOfErrors) {
                        this.isEmailLoading = false;

                        if(numberOfErrors == 0) {
                            // No errors
                            this.showEmailMessage = 'success';

                            this.$refs.emailForm.reset();

                        } else if(numberOfErrors == numberOfRequests) {
                            // Nothing sent
                            this.showEmailMessage = 'error';
                        } else if(numberOfErrors > 0 && numberOfErrors != numberOfRequests) {
                            // Partial sent
                            this.showEmailMessage = 'partial';
                        }



                        this.checkIfPlaceholderIsNeeded();
                    }
                },
                created: function () {
                },
                destroyed: function () {
                }
            })
        )
    }
});
