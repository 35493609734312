Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("floorplans")) {
        Global.components.push(
            new Vue({
                el: '#floorplans',
                store,
                data: function () {
                    return {
                        pointerClicked: false,
                        pointerCoords: null,
                        showDropdownResults: false,
                        mouseMoved: false,
                        mouseUp: false,
                        mouseDownTime: null,
                        selectedPointer: null,
                        openedPointerPresent: false,
                        openedPointerActive: false,
                        openedPointerPosition: 'top-left',
                        duplicatingPointer: null
                    }
                },
                computed: {
                    floorplans: {
                        get: function () {
                            return this.$store.getters.projectFloorplans;
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setProjectFloorplans', newValue)
                        }
                    },
                    activeFloorplan: {
                        get: function () {
                            return this.$store.getters.activeFloorplan
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setActiveFloorplan', newValue);
                        }
                    },
                    refreshFloorplan: {
                        get: function () {
                            return this.$store.state.refreshFloorplan
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setRefreshFloorplan', newValue);
                        }
                    },
                    showEditFloorplansModal: {
                        get: function () {
                            return this.$store.getters.showEditFloorplansModal
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setShowEditFloorplansModal', newValue);
                        }
                    },
                    showAddPointerPopup: {
                        get: function () {
                            return this.$store.getters.showAddPointerPopup
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setShowAddPointerPopup', newValue);
                        }
                    },
                    openedPointer: {
                        get: function () {
                            return this.$store.getters.openedPointer
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setOpenedPointer', newValue);
                        }
                    },
                    newPointer: {
                        get: function () {
                            return this.$store.getters.newPointer
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setNewPointer', newValue);
                        }
                    },
                    mousePosition: {
                        get: function () {
                            return this.$store.getters.mousePosition
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setMousePosition', newValue);
                        }
                    },
                    messageText: {
                        get: function () {
                            return this.$store.getters.messageText
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setMessageText', newValue)
                        }
                    },
                },
                watch: {
                    floorplans: function (newValue) {
                        const that = this;

                        if (newValue.length) {
                            //Als er nog geen geselecteerde plattegron is, wordt de 1e geselecteerd
                            if (this.activeFloorplan === null) {
                                this.activeFloorplan = this.floorplans[0];
                            } else {
                                //Als er wel een selecteerde plattegrond is, wordt diegene geselecteerd
                                this.floorplans.forEach(floorplan => {
                                    if (floorplan === this.activeFloorplan) {
                                        that.activeFloorplan = floorplan;
                                    }
                                })
                            }
                        }
                    },
                    pointerCoords: function () {
                        const that = this;

                        this.activeFloorplan.pointers.forEach(function (pointer) {
                            if (pointer === that.selectedPointer) {
                                pointer.left = that.pointerCoords.x;
                                pointer.top = that.pointerCoords.y;
                            }
                        });
                    },
                    refreshFloorplan(val) {
                        if (val) {
                            this.loadData();
                            this.refreshFloorplan = false;
                        }
                    }
                },
                created: function () {
                },
                mounted: function() {
                    window.addEventListener('resize', this.onWindowResize);
                    window.addEventListener('scroll', this.onWindowResize);

                    this.activeFloorplan = null;

                    this.loadData();
                },
                destroyed: function () {
                    window.removeEventListener('resize', this.onWindowResize);
                    window.removeEventListener('scroll', this.onWindowResize);
                },
                methods: {
                    loadData: function () {
                        const that = this;

                        // Hier worden alle floorplans met de pointers ingeladen.
                        API.GET(that.$el.dataset.url).then(data => {
                            that.floorplans = data.data;

                            if(that.activeFloorplan != null) {
                                that.floorplans.forEach(item => {
                                    if (that.activeFloorplan.id === item.id) {
                                        that.activeFloorplan.pointers = item.pointers;
                                    }
                                });
                            }
                        });
                    },
                    mousedown: function (e, pointer) {
                        //Er wordt hier een timestamp opgeslagen, om te kunnen bepalen of het een sleep actie of een klik actie is.
                        const that = this;
                        that.mouseDownTime = (new Date()).getTime();
                        that.selectedPointer = pointer;
                    },
                    mousemove: function (e, id) {
                        if (this.mouseDownTime !== null && this.selectedPointer !== null) {
                            this.mouseMoved = true;
                        }

                        // Hier wordt de positie van de pointer bijgewerkt wanneer de muis beweegt wordt.
                        if (this.selectedPointer !== null || this.duplicatingPointer !== null) {
                            let coords = Global.mousePositionElement(e, this.$refs.floorplan);

                            if (coords.x > this.$refs.floorplan.clientWidth || coords.y > this.$refs.floorplan.clientHeight || coords.x < 0 || coords.y < 0) {
                                return false;
                            }

                            // percentage offset bepalen
                            coords.y = (coords.y / this.$refs.floorplan.clientHeight) * 100;
                            coords.x = (coords.x / this.$refs.floorplan.clientWidth) * 100;

                            if (this.selectedPointer !== null) {
                                this.pointerCoords = coords;
                            }

                            if(this.duplicatingPointer !== null) {
                                this.duplicatingPointer.left = coords.x;
                                this.duplicatingPointer.top = coords.y;
                            }
                        }
                    },
                    mouseup: function (e, pointer) {
                        const that = this;

                        // Wanneer de tijd tussen mousedown en mousup kleiner is dan 200ms, wordt dit niet gezien als een sleep actie.

                        let timeNow = (new Date()).getTime();
                        if (timeNow - that.mouseDownTime < 200 || that.mouseDownTime === null) {

                            that.mouseDownTime = null;
                            that.mouseMoved = false;

                            // Hier wordt de pointer details geopend.
                            if (that.openedPointer === null || that.openedPointer !== pointer) {

                                that.openedPointer = pointer;

                                that.showPointerContent();
                            } else {
                                that.openedPointer = null;

                                that.hidePointerContent();
                            }

                            if (pointer.items[0] === undefined) {
                                that.showAddPointerPopup = true;
                            }

                            that.selectedPointer = null;
                            return false;
                        }

                        that.mouseDownTime = null;
                        that.mouseMoved = false;

                        // Als het wel een sleep actie is, word er een api call gedaan met de nieuwe positie van de pointer.
                        if (that.pointerCoords !== null) {

                            let formData = new FormData();

                            formData.append('_method', 'put');
                            formData.append('left', that.pointerCoords.x);
                            formData.append('top', that.pointerCoords.y);

                            if (that.selectedPointer !== null) {
                                let url = this.$el.dataset.updatePointersUrl.replace('::pointerID::', that.selectedPointer.id);

                                API.POST(url, formData).then(data => {
                                    that.loadData();
                                });
                            }
                        }

                        this.selectedPointer = null;
                    },
                    showPointerContent() {
                        // first goto display block and opacity 0
                        this.openedPointerPresent = true;

                        setTimeout(function() {
                            // then position
                            this.positionPointerContent();
                        }.bind(this), 0);

                        setTimeout(function() {
                            // then show it to the user
                            this.openedPointerActive = true;
                        }.bind(this), 0);
                    },
                    hidePointerContent() {
                        // animate out of sight
                        this.openedPointerActive = false;

                        // then remove from viewport
                        this.openedPointerPresent = false;
                    },
                    positionPointerContent() {
                        if(this.openedPointerPresent) {

                            // get the elements
                            let pointerKey = 'pointer_' + this.openedPointer.id;
                            let pointerContainer = (this.$refs.hasOwnProperty(pointerKey) ? this.$refs[pointerKey][0] : null);

                            if(pointerContainer === null) {
                                return false;
                            }

                            let pointerElement = pointerContainer.querySelector('.floorplan__pointer-icon');
                            let contentElement = pointerContainer.querySelector('.floorplan__pointer-content');

                            // get element dimensions and offset
                            let pointerBounds = pointerElement.getBoundingClientRect();
                            let contentBounds = contentElement.getBoundingClientRect();

                            // determine space between icon and right side
                            let spaceBetweenRight = window.innerWidth - (pointerBounds.left + contentBounds.width);

                            // determine space between icon and bottom side
                            let spaceBetweenBottom = window.innerHeight - ((pointerBounds.top + pointerBounds.height) + contentBounds.height);

                            // determine position
                            this.openedPointerPosition = (spaceBetweenBottom < 5 ? 'top' : 'bottom') + '-' + (spaceBetweenRight < 5 ? 'left' : 'right');
                        }
                    },
                    onWindowResize() {
                        this.positionPointerContent();
                    },
                    openAddPointerWindow: function (e) {
                        // Hier wordt add-pointer-popup geopend. Vanaf daar wordt een pointer aangemaakt.
                        // Coordinaten worden hier klaargezet dat wanneer er in de app-pointer-popup.js een pointer wordt opgeslagen zijn de coords beschikbaar via mouseposition om mee te sturen.
                        if (this.openedPointer === null && this.duplicatingPointer === null) {
                            let coords = Global.mousePositionElement(e, this.$refs.floorplan);

                            // percentage offset bepalen
                            coords.y = (coords.y / this.$refs.floorplan.clientHeight) * 100;
                            coords.x = (coords.x / this.$refs.floorplan.clientWidth) * 100;

                            this.mousePosition = coords;

                            this.showAddPointerPopup = true;
                            this.newPointer = true;
                        } else {

                            this.openedPointer = null;

                            this.hidePointerContent();
                        }
                    },
                    hideDropdownResults() {
                        this.showDropdownResults = false;
                    },
                    duplicatePointerStart: function(pointer) {
                        this.duplicatingPointer = JSON.parse(JSON.stringify(pointer));
                        this.selectedPointer = null;
                        this.openedPointer = null;
                    },
                    duplicatePointerEnd: function(e) {

                        if(this.duplicatingPointer !== null) {

                            let that = this;

                            // check de positie van de muis

                            let coords = Global.mousePositionElement(e, this.$refs.floorplan);
                            let floorplanBoundary = this.$refs.floorplan.getBoundingClientRect();

                            if(coords.x < 0 || coords.y < 0 || coords.x > floorplanBoundary.width || coords.y > floorplanBoundary.height) {
                                // Clicking outside the floorplan, cancel the duplication
                            } else {

                                let formData = new FormData();

                                formData.append('_method', 'put');
                                formData.append('left', that.duplicatingPointer.left);
                                formData.append('top', that.duplicatingPointer.top);
                                formData.append('duplicate', '1');

                                let url = that.$el.dataset.updatePointersUrl.replace('::pointerID::', that.duplicatingPointer.id);

                                let tempPointer = JSON.parse(JSON.stringify(that.duplicatingPointer));
                                tempPointer.id = null;
                                if (that.activeFloorplan != null) {
                                    that.activeFloorplan.pointers.push(tempPointer);
                                }
                                tempPointer = null;

                                API.POST(url, formData).then(data => {

                                    that.loadData();
                                    that.messageText = data.message;
                                });
                            }
                        }

                        this.duplicatingPointer = null;
                        this.selectedPointer = null;
                        this.openedPointer = null;
                    },
                }
            })
        )
    }
});
