Vue.component('accountmanager-activitylist', {
    props: ['data', 'status', 'title', 'filter'],
    data: function () {
        return {
        }
    },
    computed: {
        items() {

            let items = [];

            if(typeof(this.data) != 'undefined' && this.data.length > 0) {
                items = this.data.filter((item) => {
                    return (item.status == this.status);
                });
            }

            if(items.length > 0) {
                if (this.filter == null) {
                    return items;
                } else {
                    return items.filter((item) => {
                        return (item.model == this.filter);
                    });
                }
            }

            return items;
        },
        length() {
            if(typeof(this.items) != 'undefined' && this.items !== null) {
                return this.items.length;
            }
            return 0;
        }
    },
    methods: {
        gotoUrl(customer, url) {
            this.$emit('activitylist-click', [customer, url]);
        }
    },
    template: '<div class="accountmanager-activitylist">' +
        '<h5 class="accountmanager-activitylist__title">{{ title }} <span class="accountmanager-activitylist__title-badge">{{ length }}</span></h5>' +
            '<ul class="accountmanager-activitylist__list">' +
                '<li v-for="item in items" :key="item.id" class="accountmanager-activitylist__list-item">' +
                    '&gt; <a href="javascript://" @click.prevent="gotoUrl(item.customer, item.url)">{{ item.title }}</a> <small>({{ item.model }})</small>' +
                '</li>' +
            '</ul>' +
        '</div>'
});
