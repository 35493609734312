const Listeners = {
    MoveToInstance: new MoveTo({
        tolerance: 100,
        duration: 800
    }),
    SmoothScroll: function() {
        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
            if(anchor.getAttribute('href').length > 1) {
                anchor.addEventListener('click', function (e) {
                    e.preventDefault();

                    let target = document.querySelector(this.getAttribute('href'));
                    Listeners.MoveToInstance.move(target);
                });
            }
        });
    },
    LiveChat: function() {
        // kijken of we de button hebben
        if (! document.getElementById('start-chat')) {
            return;
        }

        // stel de api van livechat in
        window.LC_API = window.LC_API || {};

        window.LC_API.on_before_load = function() {
            window.LC_API.hide_chat_window();
        };

        window.LC_API.on_chat_window_minimized = function() {
            window.LC_API.hide_chat_window();
        };

        window.LC_API.on_after_load = function() {
            window.LC_API.disable_sounds();
        };

        document.getElementById('start-chat').addEventListener('click', function (e) {
            // chat window openen
            e.preventDefault();
            window.LC_API.open_chat_window();
            return false;
        });

        // load livechat
        window.__lc = window.__lc || {};
        window.__lc.license = 9998365;

        (function() {
            var lc = document.createElement('script'); lc.type = 'text/javascript'; lc.async = true;
            lc.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'cdn.livechatinc.com/tracking.js';
            var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(lc, s);
        })();
    },
    Init: function () {
        Listeners.SmoothScroll();
        Listeners.LiveChat();
    }
};

Barba.Dispatcher.on("transitionCompleted", function () {
    Listeners.Init();
});