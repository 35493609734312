Vue.filter('priceFormat', function(value) {
    if (isNaN(value)) {
        return '';
    }
    else if (value === 0) {
        return '-';
    }

    value = value.toFixed(2);
    value = value.replace(".", ",");
    value = "€ " + value;

    return value;
});
