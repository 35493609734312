Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("register-form")) {
        Global.components.push(
            new Vue({
                el: '#register-form',
                store,
                data: function () {
                    return {
                        slider: null,
                        confirmation: null,
                        email: null,

                        hasCaptcha: false,
                        captchaExecuted: false
                    }
                },
                components: {
                    VueRecaptcha
                },
                computed: {
                    verifyEmailUrl() {
                        let url = window.location.origin + "/register/check-username";
                        return Global.updateQueryStringParameter(url, 'email', this.email);
                    },
                    registerStep() {
                        return this.$store.getters.registerStep
                    },
                    formChanged() {
                        return this.errors.items.length;
                    }
                },
                watch: {
                    formChanged: function () {
                        let resizeEvent = window.document.createEvent('UIEvents');
                        resizeEvent.initUIEvent('resize', true, false, window, 0);
                        window.dispatchEvent(resizeEvent);
                    }
                },
                created() {
                    const that = this;
                    VeeValidate.Validator.extend('verify_username', {
                        getMessage: field => `Dit e-mailadres bestaat al.`,
                        validate: value => new Promise((resolve) => {
                            API.GET(that.verifyEmailUrl).then(function (result) {
                                resolve({
                                    valid: result.status
                                })
                            });
                        })
                    })
                },
                mounted: function () {
                    this.initializeSlider();
                },
                methods: {
                    submitForm: function () {
                        let that = this;

                        that.$validator.validateAll().then(result => {
                            if (result) {
                                if (!this.hasCaptcha || this.captchaExecuted) {
                                    Global.SubmitForm(this.$refs.registerForm, "registreren");
                                } else {
                                    this.$refs.recaptcha.execute();
                                }
                            } else {
                                // recaptcha resetten
                                this.$refs.recaptcha.reset();
                                this.captchaExecuted = false;

                                let gotoCell = 0;

                                // See if we can skip to a specific step right away
                                let firstError = this.$el.querySelector('.form__invalid-message[show]');
                                if(firstError != null) {
                                    let closestSlide = firstError.closest('.register-form__slide');
                                    if(closestSlide != null && typeof(closestSlide.dataset.index) != 'undefined') {
                                        let dataIndex = parseInt(closestSlide.dataset.index);
                                        if(!isNaN(dataIndex)) {
                                            gotoCell = dataIndex;
                                        }
                                    }
                                }

                                that.slider.selectCell(gotoCell);
                            }
                        });
                    },
                    onCaptchaVerified: function () {
                        this.captchaExecuted = true;
                        this.submitForm();
                    },
                    initializeSlider: function () {
                        let that = this;

                        let elem = document.querySelector(".register-form__slider");
                        this.slider = new Flickity(elem, {
                            contain: false,
                            pageDots: false,
                            imagesLoaded: true,
                            adaptiveHeight: true,
                            wrapAround: false,
                            prevNextButtons: false,
                            draggable: false
                        });

                        this.slider.on('change', function (index) {
                            that.$store.dispatch('setRegisterStep', index);
                        });
                    },
                    nextSlider: function () {
                        this.slider.next(true, false);
                    },
                    prevSlider: function () {
                        this.slider.previous(true, false);
                    }
                },
                destroyed: function () {
                }
            })
        )
    }
});
