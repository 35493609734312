Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("product-overview")) {
        Global.components.push(
            new Vue({
                el: '#product-overview',
                store,
                mixins: [ ItemLoaderMixin ],
                data() {
                    return {
                        lazyload: true,
                        apiNamespace: 'product-overview',
                    }
                },
                computed: {
                    showFilters: {
                        get: function () {
                            return this.$store.getters.showFilters
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setShowFilters', newValue)
                        }
                    },
                },
                watch: {
                },
                created() {
                },
                mounted() {
                    Global.eventBus.$on('productQueryInput', this.onProductQueryInput);
                    Global.eventBus.$on('productCategorySelect', this.onProductCategorySelect);
                    Global.eventBus.$on('productFilterSelect', this.onProductFilterSelect);
                    Global.eventBus.$on('productFilterReset', this.onProductFilterReset);

                    Global.eventBus.$emit('initProductFilter', this.params)
                },
                destroyed() {
                },
                methods: {
                    onProductQueryInput(query) {
                        if(query === null || query == '') {
                            if(this.params.hasOwnProperty('query')) {
                                delete(this.params.query);
                            }
                        } else {
                            this.params.query = query;
                        }

                        // Reset the page
                        this.params.page = 1;

                        this.loadData();
                    },
                    onProductCategorySelect(category) {
                        let query = (this.params.query || null);

                        this.params = {
                            category: category,
                            page: 1
                        };

                        if(query !== null && query != '') {
                            this.params.query = query;
                        }

                        this.loadData();
                    },
                    onProductFilterSelect(payload) {
                        let query = (this.params.query || null);
                        let category = (this.params.category || null);

                        this.params = {
                            category: category,
                            page: 1
                        };

                        if(query !== null && query != '') {
                            this.params.query = query;
                        }

                        for(var a in this.params) {
                            if(/^filter/.test(a)) {
                                delete(this.params[a]);
                            }
                        }

                        if(payload.filters.length > 0) {
                            payload.filters.forEach((filter) => {
                                this.params[filter[0]] = filter[1];
                            });
                        }

                        if(payload.ranges.length > 0) {
                            payload.ranges.forEach((filter) => {
                                if(filter !== null) {
                                    for(var a in filter) {
                                        this.params['filter[' + filter.id + '][' + a + ']'] = filter[a];
                                    }
                                }
                            });
                        }

                        this.loadData();

                    },
                    onProductFilterReset() {
                        this.params = {};
                        this.loadData();
                    },
                    reset() {
                        Global.eventBus.$emit('resetProductFilter', true);
                    },
                },
            })
        )
    }
});
