// Geen Barba omdat dit overal altijd bestaat
if (document.getElementById("message")) {
    const message = new Vue({
        el: '#message',
        store,
        data: function () {
            return {}
        },
        computed: {
            messageText: {
                get: function () {
                    return this.$store.getters.messageText
                },
                set: function (newValue) {
                    this.$store.dispatch('setMessageText', newValue)
                }
            },
            showMessage: {
                get: function () {
                    return this.$store.getters.showMessage
                },
                set: function (newValue) {
                    this.$store.dispatch('setShowMessage', newValue)
                }
            }
        },
        watch: {
            messageText: function (val, oldval) {
                const that = this;
                if (val !== null) {
                    that.showMessage = true;
                }
            },
            showMessage: function () {
                const that = this;
                store.commit('setShowError', false);

                window.setTimeout(function () {
                    that.showMessage = false;
                }, 2000);

                window.setTimeout(function () {
                    if (!that.showMessage) {
                        that.messageText = null;
                    }
                }, 2400);
            }
        },
        mounted: function () {
        },
        methods: {},
        created: function () {
        },
        destroyed: function () {
        }
    });
}