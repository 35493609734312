Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("add-article")) {
        Global.components.push(
            new Vue({
                el: document.getElementById("add-article"),
                store,
                data: function () {
                    return {}
                },
                computed: {
                    showSavePopup: {
                        get: function () {
                            return this.$store.getters.showSavePopup
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setShowSavePopup', newValue)
                        }
                    },
                    saveDataUrl: {
                        get: function () {
                            return this.$store.getters.saveDataUrl
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setSaveDataUrl', newValue)
                        }
                    },
                    saveDataType: {
                        get: function () {
                            return this.$store.getters.saveDataType
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setSaveDataType', newValue)
                        }
                    }
                },
                mounted: function () {
                },
                methods: {},
                created: function () {
                },
                destroyed: function () {
                }
            })
        )
    }
});