Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("knowledge-overview")) {
        Global.components.push(
            new Vue({
                el: '#knowledge-overview',
                store,
                data: function () {
                    return {
                        articlesUrl: document.getElementById("knowledge-overview").dataset.articlesUrl,
                        filtersUrl: document.getElementById("knowledge-overview").dataset.filtersUrl,
                        articles: [],
                        meta: [],
                        loadingArticles: false,
                        categories: [],
                        searchQuery: null
                    }
                },
                computed: {
                    showFilters: {
                        get: function () {
                            return this.$store.getters.showFilters
                        },
                        set: function (newValue) {
                            this.$store.dispatch('setShowFilters', newValue)
                        }
                    },
                },
                watch: {
                },
                mounted: function () {

                    Global.eventBus.$on('searchKnowledgebase', function(payload) {
                        this.searchArticles(payload);
                    }.bind(this))

                    Global.eventBus.$on('resetKnowledgebaseSearch', function() {
                        this.resetSearch();
                    }.bind(this));


                    // Try to get the search query
                    let searchQuery = Global.getQueryStringParameter(window.location.href, 'query');
                    if (searchQuery !== '' && searchQuery !== null && searchQuery !== undefined) {
                        this.searchQuery = searchQuery;
                    }

                    this.getArticles(1, false);
                    this.loadMoreOnScroll();
                },
                methods: {
                    getArticles(page, append) {

                        if(typeof(page) == 'undefined') {
                            page = 1;
                        }

                        if(typeof(append) == 'undefined') {
                            append = true;
                        }

                        const that = this;
                        if (!this.loadingArticles) {
                            this.loadingArticles = true;

                            let url = this.articlesUrl;

                            for (let key in this.categories) {
                                url = Global.updateQueryStringParameter(url, 'category[]', this.categories[key]);
                            }

                            if (that.searchQuery !== '' && that.searchQuery !== null && that.searchQuery !== undefined) {
                                url = Global.updateQueryStringParameter(url, 'query', that.searchQuery);
                            }

                            url = Global.updateQueryStringParameter(url, 'page', page);

                            API.GET(url).then(response => {
                                if(append) {
                                    that.articles = that.articles.concat(response.data);
                                } else {
                                    that.articles = response.data;
                                }
                                that.meta = response.meta;
                                that.loadingArticles = false;
                            });
                        }
                    },
                    loadMoreArticles() {
                        if (!this.loadingArticles) {
                            if (this.meta.last_page > this.meta.current_page) {
                                this.getArticles((this.meta.current_page+1), true);
                            }
                        }
                    },
                    loadMoreOnScroll() {
                        window.onscroll = () => {
                            if (!this.loadingArticles) {
                                let overviewBoundary = this.$el.getBoundingClientRect();
                                if (window.innerHeight > (overviewBoundary.height + overviewBoundary.top)) {
                                    this.loadMoreArticles();
                                }
                            }
                        }
                    },
                    onFilterSubmit() {
                        this.getArticles(1, false);
                    },
                    searchArticles: function(query) {
                        this.searchQuery = query;

                        let url = window.location.href;

                        if (this.searchQuery !== '' && this.searchQuery !== null && this.searchQuery !== undefined) {
                            url = Global.updateQueryStringParameter(url, 'query', this.searchQuery);
                        } else {
                            url = Global.removeQueryStringParameter(url, 'query');
                        }

                        window.history.replaceState({}, document.title, url);

                        this.getArticles(1, false);

                    },
                    resetSearch: function() {
                        let url = window.location.href;

                        url = Global.removeQueryStringParameter(url, 'query');

                        window.history.replaceState({}, document.title, url);

                        this.searchQuery = '';
                        this.categories = [];

                        this.getArticles(1, false);
                    }
                },
                created: function () {
                },
                destroyed: function () {
                }
            })
        )
    }
});
