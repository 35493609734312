Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("accountmanager-activities")) {
        Global.components.push(
            new Vue({
                el: '#accountmanager-activities',
                store,
                data: function () {
                    return {
                        stateOpen: false,
                        activitiesUrl: null,
                        activitiesData: null,
                        activitiesLoading: false,
                        customerId: null,
                        redirectUrl: null,
                        filter: null
                    }
                },
                created() {
                    Global.eventBus.$on('showCustomerActivities', function(payload) {
                        this.stateOpen = true;
                        this.customerId = payload;

                        this.getActivities();
                    }.bind(this));

                    Global.eventBus.$on('showGlobalActivities', function() {
                        this.stateOpen = true;
                        this.customerId = null;

                        this.getActivities();
                    }.bind(this));
                },
                beforeMount() {
                    if(this.$el.dataset !== null) {
                        for(var a in this.$el.dataset) {
                            if(this.$data.hasOwnProperty(a)) {
                                this.$data[a] = this.$el.dataset[a];
                            }
                        }
                    }

                    this.getActivities();

                },
                mounted() {
                },
                watch: {
                },
                computed: {
                    activities() {
                        if(this.activitiesData !== null && this.activitiesData.hasOwnProperty('data')) {
                            return this.activitiesData.data;
                        }

                        return [];
                    },
                    customer() {
                        if(this.activitiesData !== null && this.activitiesData.hasOwnProperty('customer')) {
                            return this.activitiesData.customer;
                        }

                        return null;
                    }
                },
                methods: {
                    getActivities() {

                        this.activitiesLoading = true;

                        let url = this.activitiesUrl;

                        // Customer ID
                        let customer = parseInt(this.customerId);
                        if(!isNaN(customer) && customer > 0) {
                            url = Global.updateQueryStringParameter(url, 'customer', customer);
                        }

                        // Execute XHR request
                        API.GET(url).then(response => {
                            this.activitiesData = response;

                            this.activitiesLoading = false;
                        });
                    },
                    onCloseClick() {
                        this.stateOpen = false;
                        this.customerId = null;
                        this.redirectUrl = null;

                        this.getActivities();
                    },
                    onFilterClick(state) {
                        this.filter = state;
                    },
                    onSubmitClick() {
                        this.$refs.form.submit();
                    },
                    onActivityClick(payload) {
                        this.customerId = payload[0];
                        this.redirectUrl = payload[1];

                        this.$nextTick(() => {
                            this.onSubmitClick();
                        });
                    }
                }
            })
        );
    }
});
